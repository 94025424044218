import { FC } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { stepType } from "../types"

type PropsType = {
  stepsData: stepType[],
  step: number
}

export const Stepper: FC<PropsType> = ({ stepsData, step = 2 }) => {
  return (
    <>
      <Box
        sx={{
          marginTop: '16px',
          display: 'flex',
          alignItems: 'center',
          marginLeft: 2
        }} >
        {
          stepsData?.map((stepData: { step: number, name: string }, index) => (
            <Box key={stepData?.step}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}>
              <Grid container
                sx={{
                  py: '5px',
                  minWidth: '240px',
                  borderRadius: '16px',
                  backgroundColor: stepData?.step === step ? 'rgb(221, 255, 84)' : 'rgb(34, 35, 36)',
                  alignItems: 'center'
                }}>
                <Grid item>
                  <Box sx={{
                    mx: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '21px',
                    height: '21px',
                    borderRadius: '50%',
                    backgroundColor: '#000'
                  }} >
                    <Typography>{stepData?.step}</Typography> 
                  </Box>
                </Grid>
                <Grid item  >
                  <Typography sx={{ color: stepData?.step === step ? '#000' : '#fff' }} >{stepData?.name}</Typography> 
                </Grid>
              </Grid>
              {index < stepsData?.length - 1 && (
                <Box 
                  sx={{
                    m: 2, border: '1px dashed rgba(255, 255, 255, 0.50)',
                    width: '48px',
                    height: '1px',
                  }}>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </>
  )
}