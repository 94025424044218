import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// local import
import { UploadReportSliceType } from '../types';

const initialState: UploadReportSliceType = {
  inProcess: undefined,
  error: undefined,
  message: undefined,
};

const uploadReportSlice = createSlice({
  name: 'uploadreport',
  initialState,
  reducers: {
    uploadReport: (state, { payload }) => {
      state.inProcess = true;
    },
    uploadReportFulfilled: (state, action) => {
      state.inProcess = false;
      state.error = false
      state.message = action.payload
    },
    uploadReportRejected: (state, action) => {
      state.inProcess = false;
      state.error = true;
      state.message = action.payload
    },

    resetUploadReportState : () => initialState
  }
});

export const { uploadReport, uploadReportFulfilled, uploadReportRejected, resetUploadReportState } = uploadReportSlice.actions;

export default uploadReportSlice.reducer;
