import { createSlice } from '@reduxjs/toolkit';
// local import
import { SummarySliceType } from '../types';
import { toast as toasts } from 'react-toastify';
import AlertComponenet from 'components/common/AlertComponents';

const initialState: SummarySliceType = {
  auditSummaryData: undefined,
  inProcess: undefined,
};

const auditSummarySlice = createSlice({
  name: 'auditsummary',
  initialState,
  reducers: {
    getAuditSummary: (state, { payload }) => {
      state.inProcess = true;
    },
    auditSummaryFulfilled: (state, action) => {
      state.inProcess = false;
      state.auditSummaryData = action.payload
    },
    auditSummaryRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'audit-summary-error'});
      state.inProcess = false;
    }
  }
});

export const { getAuditSummary, auditSummaryFulfilled, auditSummaryRejected } = auditSummarySlice.actions;

export default auditSummarySlice.reducer;
