import {
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

const tableHeader = ['Mount Point', 'Utilization', 'Unrealized Savings', 'Volume Status', ''];

const getPadding = (index: number) => {
  if(index === 0) return '3.75rem'
  else return '2rem'
}

const InnerTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {tableHeader.map((header: string, index: number) => (
          <TableCell align="left" key={`${header + index}`} sx={{
            pl: getPadding(index),
            py: '16px',
            width: `${index === tableHeader.length - 1 ? '80px' : '280px'}`,
          }}>
            <Typography variant="body1" fontSize={12} sx={{textTransform: 'uppercase', color: 'rgba(255, 255, 255, 0.5)'}}>{header}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default InnerTableHead;
