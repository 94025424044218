import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from '../api';
import * as slice from './kubManaged.slice';

export function* onGetManagedCluster({ payload }: any): SagaIterator {
  try {
    const data = yield call(api.getManagedClustersApi, payload);
    yield put(slice.getManagedClusterSuccess(data));
  } catch (e: any) {
    yield put(slice.getManagedClusterError(e));
  }
}

export function* onGetDescribeCluster({ payload }: any): SagaIterator {
  try {
    const data = yield call(api.getDescribeClusterApi, payload);
    yield put(slice.getDescribeClusterSuccess(data));
  } catch (e: any) {
    yield put(slice.getDescribeClusterError(e));
  }
}

export function* onGetListPv({ payload }: any): SagaIterator {
  try {
    const data = yield call(api.getManagedListPvsApi, payload);
    yield put(slice.getManagedListPvSuccess(data));
  } catch (e: any) {
    yield put(slice.getManagedListPvrError(e));
  }
}

export function* onGetPvOPT({ payload }: any): SagaIterator {
  try {
    const data = yield call(api.getPvOperationTimelineApi, payload);
    yield put(slice.getPvOPTSuccess(data));
  } catch (e: any) {
    yield put(slice.getPvOPTError(e));
  }
}

export function* onGetPvMetrics({ payload }: any): SagaIterator {
  try {
    const data = yield call(api.getPvMetricsApi, payload);
    yield put(slice.getPvMetricsSuccess(data));
  } catch (e: any) {
    yield put(slice.getPvMetricsError(e));
  }
}

// Watcher Saga
export function* kubManagedWatcherSaga(): SagaIterator {
  yield takeEvery(slice.getManagedClusterInit.type, onGetManagedCluster);
  yield takeEvery(slice.getDescribeClusterInit.type, onGetDescribeCluster);
  yield takeEvery(slice.getManagedListPvInit.type, onGetListPv);
  yield takeEvery(slice.getPvOPTInit.type, onGetPvOPT);
  yield takeEvery(slice.getPvMetricsInit.type, onGetPvMetrics);
}

export default kubManagedWatcherSaga;
