import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getAuditSummaryApi } from '../api';
// local import
import { auditSummaryFulfilled, auditSummaryRejected } from '.';

export function* onGetAuditSummary({ payload }: any): SagaIterator {
  try {
    const { data }  = yield call(() => getAuditSummaryApi(payload));
    yield put(auditSummaryFulfilled(data));
  } catch (e: any) {
    yield put(auditSummaryRejected(e));
  }
}

// Watcher Saga
export function* getAuditSummaryWatcherSaga(): SagaIterator {
  yield takeEvery('auditsummary/getAuditSummary', onGetAuditSummary);
}

export default getAuditSummaryWatcherSaga;
