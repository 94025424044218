import { Box } from '@mui/material';
import { SimpleToolTip } from 'components/StylesTooltip';
import { FC } from 'react';
import { diskCompatibleMsg, diskNonCompatibleMsg } from '../../constant';
import { DiskTypePropsType } from '../../types';
import VersionChip from './VersionChip';

const DiskTypeChip: FC<DiskTypePropsType> = ({ diskCompatible }) => {
  return (
    <Box>
      <SimpleToolTip
        showIcon={false}
        text={diskCompatible ? diskCompatibleMsg : diskNonCompatibleMsg}>
        <VersionChip diskCompatible={diskCompatible} />
      </SimpleToolTip>
    </Box>
  );
};

export default DiskTypeChip;
