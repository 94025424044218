import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Chip } from '@mui/material';
import { FC } from 'react';

interface VersionChipType {
  diskCompatible?: boolean;
}

const VersionChip: FC<VersionChipType> = ({ diskCompatible }) => {
  const getDiskTypeIcon = () => {
    if (diskCompatible) {
      return <ArrowUpwardIcon data-testid="compatible"/>;
    } else {
      return <NotInterestedIcon data-testid="noncompatible"/>;
    }
  };

  const getColor = () => {
    if (diskCompatible) {
      return '#58E043';
    } else {
      return 'rgba(255, 255, 255, 0.50)';
    }
  };
  const getColorBg = () => {
    if (diskCompatible) {
      return 'rgba(88, 224, 67, 0.10)';
    } else {
      return 'rgba(255, 255, 255, 0.10)';
    }
  };
  return (
    <Chip
      sx={{
        borderRadius: 2,
        border: '1px solid rgba(88, 224, 67, 0.20)',
        background: getColorBg(),
        '&.MuiChip-root .MuiChip-icon': {
          color: getColor(),
          fontSize: '18px',
          margin: 0
        },
        '&.MuiChip-root': {
          fontSize: '12px',
          color: getColor(),
          padding: '3px 6px 3px 3px',
          height: 'auto'
        },
        '.MuiChip-label': {
          padding: '0 2px 0 3px'
        }
      }}
      icon={getDiskTypeIcon()}
      label="v2"
    />
  );
};

export default VersionChip;
