import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { uploadReportApi } from '../api';
// local import
import { uploadReportFulfilled, uploadReportRejected } from '.';

export function* onUploadReport({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => uploadReportApi(payload));
    yield put(uploadReportFulfilled(data));
  } catch (e: any) {
    yield put(uploadReportRejected(e));
  }
}

// Watcher Saga
export function* uploadReportWatcherSaga(): SagaIterator {
  yield takeEvery('uploadreport/uploadReport', onUploadReport);
}

export default uploadReportWatcherSaga;
