import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Partitions, UnManagedVmsRes } from '../types';

const useTotalSaving = () => {
  const { scheduleModalPropsList } = useAppSelector((state: RootState) => state.unmanagedVm);

  const getTotalSavingAdd = (selectedInstance: UnManagedVmsRes, selectedPartition: Partitions) => {
    if (scheduleModalPropsList?.totalSaving) {
      // if any partition onboared to v2 disk calculate the v2 saving
      if (selectedInstance?.anyPartitionOnboardedToV2) {
        return (
          scheduleModalPropsList?.totalSaving +
          selectedPartition?.unrealizedV2OnboardingSavings?.amount
        );
      } else {
        return (
          scheduleModalPropsList?.totalSaving +
          Number(selectedPartition?.unrealizedSavings.slice(1))
        );
      }
    } else {
      if (selectedInstance?.anyPartitionOnboardedToV2) {
        return selectedPartition?.unrealizedV2OnboardingSavings?.amount;
      } else {
        return Number(selectedPartition?.unrealizedSavings.slice(1));
      }
    }
  };

  const getTotalSavingSub = (selectedInstance: UnManagedVmsRes, selectedPartition: Partitions) => {
    if (scheduleModalPropsList?.totalSaving) {
      if (selectedInstance?.anyPartitionOnboardedToV2) {
        return (
          scheduleModalPropsList?.totalSaving -
          selectedPartition?.unrealizedV2OnboardingSavings?.amount
        );
      } else {
        return (
          scheduleModalPropsList?.totalSaving -
          Number(selectedPartition?.unrealizedSavings.slice(1))
        );
      }
    } else {
      return 0;
    }
  };

  const getTotalSavingWithConversionAdd = (
    selectedInstance: UnManagedVmsRes,
    selectedPartition: Partitions
  ) => {
    if (
      selectedInstance?.v2OnboardingFeatureEnabled &&
      selectedInstance?.instanceCompatibleForV2Onboard &&
      selectedInstance?.currentDiskRequirementsForV2IconDisplay
    ) {
      if (
        scheduleModalPropsList?.totalSavingWithConversion &&
        selectedPartition?.unrealizedV2OnboardingSavings?.amount
      ) {
        return (
          scheduleModalPropsList?.totalSavingWithConversion +
          selectedPartition?.unrealizedV2OnboardingSavings?.amount
        );
      } else {
        return selectedPartition?.unrealizedV2OnboardingSavings?.amount;
      }
    } else {
      if (scheduleModalPropsList?.totalSavingWithConversion)
        return (
          scheduleModalPropsList?.totalSavingWithConversion +
          Number(selectedPartition?.unrealizedSavings.slice(1))
        );
    }
  };

  const getTotalSavingWithConversionSubstract = (
    selectedInstance: UnManagedVmsRes,
    selectedPartition: Partitions
  ) => {
    if (
      selectedInstance?.v2OnboardingFeatureEnabled &&
      selectedInstance?.instanceCompatibleForV2Onboard &&
      selectedInstance?.currentDiskRequirementsForV2IconDisplay
    ) {
      if (
        scheduleModalPropsList?.totalSavingWithConversion &&
        selectedPartition?.unrealizedV2OnboardingSavings?.amount
      ) {
        return (
          scheduleModalPropsList?.totalSavingWithConversion -
          selectedPartition?.unrealizedV2OnboardingSavings?.amount
        );
      } else {
        return 0;
      }
    } else {
      if (scheduleModalPropsList?.totalSaving) {
        return (
          scheduleModalPropsList?.totalSaving -
          Number(selectedPartition?.unrealizedSavings.slice(1))
        );
      }
    }
  };
  return {
    getTotalSavingAdd,
    getTotalSavingSub,
    getTotalSavingWithConversionSubstract,
    getTotalSavingWithConversionAdd
  };
};

export { useTotalSaving };