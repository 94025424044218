import * as amplitude from "@amplitude/analytics-browser";

class PartnerLoginInstrumentation {
  private loginEvent: string;
  private toggleAccountsEvent: string;

  constructor() {
    this.loginEvent = 'partner_login';
    this.toggleAccountsEvent = 'partner_toggle_accounts';
  }

  partnerLogin({ partnerAccount }: { partnerAccount: string }) {
    const eventProps = {
      partner_account: partnerAccount,
    };

    this.pushEvent(this.loginEvent, eventProps);
  }

  partnerToggleAccounts({ partnerAccount, customerAccount}: { partnerAccount: string, customerAccount: string }) {
    const eventProps = {
      partner_account: partnerAccount,
      customer_account: customerAccount,
    }
    this.pushEvent(this.toggleAccountsEvent, eventProps);
  }

  private pushEvent(eventName: string, eventProps: any) {
    amplitude.track(eventName, eventProps);
  }
}

export default PartnerLoginInstrumentation;
