import { Table } from '@mui/material';
import { BulkOnboardInstancesInfo, UnManagedVmsRes } from 'features/scalar/components/unManagedVm/types';
import { FC } from 'react';
import InnerTableHead from './InnerTableHead';
import InnerTableRow from './InnerTableRow';

interface InnerTableType {
  instance: UnManagedVmsRes;
  instanceIndex: number;
  onboardingGroup: string;
  setOnboardingGroup: any;
  selectedPartition: BulkOnboardInstancesInfo[];
  setSelectedPartition: any;
}

const InnerTable: FC<InnerTableType> = ({
  instance,
  instanceIndex,
  onboardingGroup,
  setOnboardingGroup,
  selectedPartition,
  setSelectedPartition
}) => {

  return (
    <Table sx={{ backgroundColor: '#161718' }}>
      <InnerTableHead />
      <InnerTableRow
        instance={instance}
        instanceIndex={instanceIndex}
        onboardingGroup={onboardingGroup}
        setOnboardingGroup={setOnboardingGroup}
        selectedPartition={selectedPartition}
        setSelectedPartition={setSelectedPartition}
      />
    </Table>
  );
};

export default InnerTable;
