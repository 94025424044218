import CloseIcon from '@mui/icons-material/Close';
import { Box, styled, Dialog, DialogContent, IconButton } from '@mui/material';
import React, { FC } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    backgroundColor: 'rgba(0, 0, 0, 0.001)'
  },
  '& .MuiPaper-elevation': {
    background: 'rgba(0, 0, 0, 1)',
    backdropFilter: 'blur(32.5px)',
    borderRadius: '16px',
    maxWidth: 'initial'
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    minHeight: '174px',
    maxHeight: '96vh',
    width: '599px',
    border: 'unset'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

interface ModalProp {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  width?: number;
  bg?: string;
  style?: any;
  hideCloseIcon?: boolean
}

const CustomizedDialogs: FC<ModalProp> = ({ open, handleClose, children, width, bg, style, hideCloseIcon }) => {
  return (
    <div data-testid="custom-dialog">
      <BootstrapDialog onClose={!hideCloseIcon ? handleClose : undefined} aria-labelledby="customized-dialog-title" open={open} sx={{
        '& .MuiDialogContent-root': {
        width: width ? width : 600,
        background: bg ? bg : 'inherit',
        overflow: 'hidden'
        },
        ...style
      }}>
        <DialogContent dividers>
          {children}
          {handleClose && !hideCloseIcon && (
            <Box
              sx={{
                position: 'absolute',
                right: 24,
                top: 20,
                borderRadius: '12px',
                backgroundColor: 'rgba(31, 32, 35, 0.28)',
                border: '1px solid rgba(255, 255, 255, 0.10)',

              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  color: (theme) => theme.palette.primary.main
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialogs;
