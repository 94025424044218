import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import Buttons from 'components/buttons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/storage';

const PolicyModal: FC<any> = ({ handleModalClose, policy, cloudType, login }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogin = async () => {
    if (!storage.getAccountsPermission()) {
      storage.setAccountsPermission({ AUTO_SCALER: true, AUDIT: false });
    }
    storage.setUpdatePermission({...storage.gettUpdatePermission(), isUpdate:  true});
    instance.initialize();
    navigate('/accounts/callback');
  };
  return (
    <Box>
      <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={1}>
        Review the policy
      </Typography>
      <Typography variant="body1" gutterBottom fontSize={16} lineHeight="22px" mb={1}>
        {cloudType !== 'GCP' && 'To define the {cloudType} permissions'}
        {cloudType === 'GCP' && 'To define the Google cloud permissions for the IAM role'}
      </Typography>
      <Box
        sx={{
          background: '#222222',
          overflow: 'scroll',
          borderRadius: '16px',
          maxHeight: '66vh'
        }}>
        <pre
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(policy, null, 2)
          }}
        />
      </Box>
      <Box sx={{ marginTop: '30px', display: 'flex', justifyContent: 'end' }}>
        {!login && <Buttons btnVarient="contained" text="Got it" onClick={handleModalClose} />}
        {login && <Buttons btnVarient="contained" text="Login to AD" onClick={handleLogin} />}
      </Box>
    </Box>
  );
};

export default PolicyModal;
