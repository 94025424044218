import { Box, Grid, Typography } from '@mui/material';
import Buttons from 'components/buttons';
import CustomizedDialogs from 'components/modalContainer';
import { PermissionsKeys } from 'features/accounts/types';
import { FC, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getProductPolicies } from '../api';
import PolicyModal from './PolicyModal';
import useModal from 'hooks/useModal';
import toast from 'react-hot-toast';

interface StepTwoProps {
  step?: string;
}

const StepTwo: FC<StepTwoProps> = ({ step }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [policy, setPolicy] = useState<any>({});
  const { permissions, cloudType } = useAppSelector((state) => state.accounts);
  const { handleModalClose, openModal, setOpenModal } = useModal();

  const handleReviewPolicy = () => {
    setLoading(true);
    const productList = [];
    if (permissions) {
      productList.push(
        ...Object.keys(permissions).filter((i: string) => permissions[i as PermissionsKeys])
      );
    }
    getProductPolicies({
      productList: productList,
      cloudProviderCode: cloudType
    })
      .then((res) => {
        setPolicy(res.data);
        setLoading(false);
        setOpenModal(true);
      })
      .catch((err: any) => {
        toast.error('Failed to get Policy');
        setLoading(false);
      });
  };

  return (
    <>
      <CustomizedDialogs
        handleClose={handleModalClose}
        open={openModal}
        width={cloudType === 'AZURE' ? 940 : 682}
        bg={'#282B27'}>
        <PolicyModal handleModalClose={handleModalClose} policy={policy} cloudType={cloudType} />
      </CustomizedDialogs>
      <Grid container spacing={2} maxWidth={'1035px'} sx={{ mx: 'auto' }}>
        <Grid item xs={10.5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: 'rgba(221, 255, 85, 0.1)',
              backdropFilter: 'blur(24.5px)',
              px: '24px',
              py: '8px',
              borderBottomLeftRadius: '16px',
              borderBottomRightRadius: '16px'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }}>
              <Box>
                <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={0.5}>
                  {cloudType === 'AWS' && 'Review JSON policy for AWS permissions'}
                  {cloudType === 'AZURE' && 'Review permissions necessary to integrate with Azure'}
                  {cloudType === 'GCP' &&
                    'Review permissions necessary to integrate with Google Cloud'}
                </Typography>
                <Typography variant="body2" gutterBottom fontSize={16} lineHeight="22px" mb={0}>
                  {cloudType === 'AZURE' && 'The policy defines Azure permissions for the IAM role'}
                  {cloudType === 'AWS' && 'The policy defines AWS permissions for the IAM role'}
                  {cloudType === 'GCP' &&
                    'The policy defines Google Cloud permissions for the IAM role.'}
                </Typography>
              </Box>
              <Buttons
                disabled={
                  permissions?.AUDIT || permissions.AUTO_SCALER ? false : step ? false : true
                }
                btnVarient="contained"
                text="Review Policy"
                onClick={handleReviewPolicy}
                loading={loading}
                sx={{ width: '154px' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StepTwo;
