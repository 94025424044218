import { CloudProviderCaps } from "types/common";

const GCP_AZURE_MAX_ACCOUNTS = 15;
const AWS_MAX_ACCOUNTS = 5;

export const isGcpOrAzureAccount = (type: CloudProviderCaps | string) => 
  type === 'AZURE' || type === 'GCP'

export const alias = (type: CloudProviderCaps | string) => {
  if (type === 'AZURE') return 'subscription(s)' 
  else return 'project(s)'
}

export const maxAccountsAllowed = (type: CloudProviderCaps | string) => {
  if(isGcpOrAzureAccount(type)) {
    return GCP_AZURE_MAX_ACCOUNTS;
  }

  return AWS_MAX_ACCOUNTS;
}