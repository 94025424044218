import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useOktaAuth } from '@okta/okta-react';
import { getUnixTime } from 'date-fns';
import { loginRequest } from 'features/auth/config/azureConfig';
import { useCookies } from 'hooks/useCookies';
import { useEffect, useState } from 'react';
import { checkTokenExpiry, isUndefineNull } from 'utils/common';
import storage from 'utils/storage';

const useAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { instance, accounts, inProgress } = useMsal();
  const [auth, setAuth] = useState<boolean>();
  const { readCookie } = useCookies();
  const oktaToken = readCookie('okta-token-storage_accessToken');

  if (checkTokenExpiry()) {
    if (inProgress === InteractionStatus.None && storage.getAuthType() === 'azure') {
      instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: accounts[0]
        })
        .then((res) => {
          if (res.expiresOn) storage.setTokenExpire(String(getUnixTime(new Date(res.expiresOn))));
          storage.setAuthType('azure');
          storage.setToken(res.accessToken);
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: loginRequest.scopes,
              account: accounts[0]
            });
          }
        });
    }
  }

  useEffect(() => {
    setAuth(authState?.isAuthenticated);
  }, [oktaAuth, authState]);

  oktaAuth.tokenManager.on('renewed', function (key: string, newToken: any) {
    if (key === 'accessToken') {
      storage.setToken(newToken.accessToken);
      storage.setTokenExpire(newToken?.expiresAt);
    }
  });

  if (oktaToken) {
    if (oktaAuth.tokenManager.hasExpired(oktaToken)) {
      oktaAuth.tokenManager.renew('accessToken').then((res) => {
        alert();
        const token: any = res;
        storage.setToken(token?.accessToken);
        storage.setTokenExpire(token?.expiresAt);
      });
    }
  }

  oktaAuth.tokenManager.on('expired', function () {
    oktaAuth.tokenManager.renew('accessToken').then((res) => {
      const token: any = res;
      storage.setToken(token?.accessToken);
      storage.setTokenExpire(token?.expiresAt);
    });
  });

  const savedToken = () => {
    return !isUndefineNull(storage.getToken()) && !isUndefineNull(storage.getAuthType());
  };

  return { auth, savedToken };
};

export default useAuth;
