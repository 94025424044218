import { Box, Typography } from '@mui/material';
import Server from '../../../../../assets/images/server.svg';
import { useAppSelector } from 'store/hooks';
import { FC } from 'react';

interface NovmsMessageProps {
  title?: string;
  subTitle?: string;
  hideSubTtitle?: boolean;
}
const NovmsMessage: FC<NovmsMessageProps> = ({ title, subTitle, hideSubTtitle = false }) => {
  const { isSearchActive } = useAppSelector((state) => state.searchSort);

  return (
    <Box
      sx={{
        border: '1px solid #454546',
        background: 'rgba(255, 255, 255, 0.05)',
        width: '100%',
        p: 4,
        borderRadius: 4
      }}>
      <Box
        sx={{
          my: 4,
          width: '100%',
          textAlign: 'center',
          mx: 'auto'
        }}>
        <img src={Server} alt="server" />
        <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px">
          {title || 'No results found!'}
        </Typography>
        {!hideSubTtitle && (
          <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px">
            We could not find the VM that you are looking for.
            {isSearchActive ? ' Please note partial string search is currently not supported.' : ''}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NovmsMessage;
