import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SortIcon from '@mui/icons-material/Sort';
import { Box, List, ListItemText, MenuItem } from '@mui/material';
import { StyledMenu } from 'components/common/styledMUIcomp/StyledMenu';
import { filterDropdownDefaultText, filterDropdownList } from 'constants/filter';
import { FC } from 'react';
import { FilterOption } from 'types/common';
import useSort from '../hook/useSort';
import { StyledListItemButton } from '../style/MuiStyle';

interface SortBoxProps {
  filterKey: keyof FilterOption;
}

const SortBox: FC<SortBoxProps> = ({ filterKey }) => {
  const {
    anchorEl,
    openSortList,
    selectedValue,
    handleSortMenuClose,
    handleSortMenuOpen,
    handleSort
  } = useSort();

  return (
    <Box>
      <List component="nav" sx={{ p: 0, display: 'flex', alignItems: 'center', minWidth: 200 }}>
        <StyledListItemButton focus={anchorEl} onClick={handleSortMenuOpen}>
          <SortIcon sx={{ fontSize: '16px' }} />
          <ListItemText
            primary={
              selectedValue[filterKey] ? selectedValue[filterKey] : filterDropdownDefaultText[filterKey]
            }
          />
          {anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledListItemButton>
      </List>

      <StyledMenu
        anchorEl={anchorEl}
        open={openSortList}
        onClose={handleSortMenuClose}
        data-testid="open-menu">
        {filterDropdownList[filterKey]?.map((sort: any) => {
          const key = Object.keys(sort)[0];
          const value = sort[key];
          return (
            <MenuItem
              key={value}
              value={value}
              onClick={(event) => handleSort(event, value, filterKey)}>
              {value}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Box>
  );
};

export default SortBox;
