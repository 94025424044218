import {
  Button,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

const tableHeader = [
  'VM details',
  'Utilization',
  'Total Unrealized Savings',
  'Status',
  ' '
];

function CollapsibleTable() {
  return (
    <TableHead>
      <TableRow sx={{
        '.MuiTableRow-root': {
          backgroundColor: 'rgba(31, 32, 35, 0.28)'
        },
      }}>
        {tableHeader.map((header: string, index: number) => (
          <TableCell align="left" key={`${header + index}`} sx={{
            pl: `${index === 0 ? '44px' : '18px'}`,
            width: `${index === tableHeader.length - 1 ? '80px' : '280px'}`,
            py: '24px'
          }}>
            {index !== 4 && (
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: '#161718',
                  border: 'none',
                  fontSize: '14px',
                  px: `10px`,
                  '&:hover': {
                    backgroundColor: '#161718',
                    border: 'none'
                  }
                }}>
                {header}
              </Button>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CollapsibleTable;
