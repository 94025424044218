import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface TitleType {
  title: string;
}

const Title: FC<TitleType> = (props) => {
  return (
      <Typography variant="h2" gutterBottom fontSize={24} lineHeight="36px" mt={1} pl={4}>
        {props.title}
      </Typography>
  )
}

export default Title;