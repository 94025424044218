import { Box, Divider, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import GlobalSpinner from 'components/GlobalSpinner';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMirrorInprogressMetrics } from '../../store';
import InProgressGraph from './InProgressGraph';


const OnboardingProgress: FC = () => {
  const dispatch = useAppDispatch()
  const { mirrorMetrics, mirrorMigration } = useAppSelector(state => state.unmanagedVm)

  useEffect(() => {
    dispatch(getMirrorInprogressMetrics(mirrorMigration?.payload))
  }, [dispatch])

  return (
    <Box>
      {mirrorMetrics?.inProgress && (
        <GlobalSpinner height='500px' />
      )}
      {!mirrorMetrics?.inProgress && (
        <>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography
              variant="h2"
              gutterBottom
              fontSize={20}
              lineHeight="28px"
              mb={1}
              sx={{ display: 'flex', gap: 1 }}>
              Onboarding Progress
            </Typography>
            <Box sx={{ width: '80px' }}>
              <LinearProgress variant="determinate" value={Number(mirrorMetrics?.data?.onboardPercentageCompletion) || 0} />
            </Box>
            <Typography variant="body2" fontSize={14}>
              {`${mirrorMetrics?.data?.onboardPercentageCompletion}%` + ' done'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <InstanceInfo title="Instance:" text={mirrorMigration?.props?.instanceName} />
            <InstanceInfo title="•" />
            <InstanceInfo title="Mount Point:" text={mirrorMigration?.payload.mountPoint} />
            <InstanceInfo title="•" />
            <InstanceInfo title="OS:" text={mirrorMigration?.props?.fullOsName} />
          </Box>
          <Divider sx={{ my: 2, borderColor: 'rgba(69, 69, 70, 1)', height: '1px' }} />
          <InProgressGraph />
        </>
      )}
    </Box>
  );
};

export default OnboardingProgress;

const InstanceInfo: FC<any> = ({ title, text }) => {
  return (
    <Typography sx={{ maxWidth: '350px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }} variant="body2" fontSize={16} color="rgba(255, 255, 255, 0.5)">
      {`${title} ${text || ''}`}
    </Typography>
  );
};
