import { OktaAuth } from '@okta/okta-auth-js';
import config from 'config';
import { OktaConfigType } from 'types/signin.model';
import { ORIGIN } from "utils/common";

export const oktaConfig: OktaConfigType = {
  clientId: `${config.oktaClientId}`,
  issuer: `${config.oktaIssuer}`,
  redirectUri: `${ORIGIN}${config.oktaRedirectUri}`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: config.disableHttps,
  storageManager: {
    token: {
      storageTypes: ['sessionStorage']
    },
    cache: {
      storageTypes: ['sessionStorage']
    },
    transaction: {
      storageTypes: ['sessionStorage']
    }
  },
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 30, // expire 5 mins early this can be set only in local env, in remote this value is reset to 30s
  }
};

export const oktaConfiguration = new OktaAuth(oktaConfig);
