import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getScalarMetricsApi, getManagedVmsApi, getOperationalTimelineApi } from '../api';
// lo
import {
  getScalarMetricsFulfilled,
  getScalarMetricsRejected,
  getManagedVmsFulfilled,
  getManagedVmsRejected,
  getOperationalTimelineFulfilled,
  getOperationalTimelineRejected,
} from '.';

export function* onGetMetrics({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getScalarMetricsApi(payload));
    yield put(getScalarMetricsFulfilled(data));
  } catch (e: any) {
    yield put(getScalarMetricsRejected(e));
  }
}

export function* onGetManagedVms({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getManagedVmsApi(payload));
    yield put(getManagedVmsFulfilled({
      data, userprops: payload
  }));
  } catch (e: any) {
    yield put(getManagedVmsRejected({ 
      e, userprops: payload
    }));
  }
}
export function* onOperationTimeline({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getOperationalTimelineApi(payload));
    yield put(getOperationalTimelineFulfilled(data));
  } catch (e: any) {
    yield put(getOperationalTimelineRejected(e));
  }
}

// Watcher Saga
export function* scalarMetricsWatcherSaga(): SagaIterator {
  yield takeEvery('managedvm/getScalarMetrics', onGetMetrics);
  yield takeEvery('managedvm/getManagedVms', onGetManagedVms);
  yield takeEvery('managedvm/getOperationalTimeline', onOperationTimeline);
}

export default scalarMetricsWatcherSaga;
