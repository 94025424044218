import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { GlobalBarLoader } from 'components/GlobalSpinner';
import { SearchBox, SortBox } from 'components/searchAndSort';
import { resetSearchSort } from 'components/searchAndSort/store/searchSort.slice';
import { filterKeys } from 'constants/filter';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const style = {
  display: 'flex',
  gap: 2,
  alignItems: 'center'
};

const SearchSortUmvm = () => {
  const dispatch = useAppDispatch()
  const { unmanagedVmsData, status } = useAppSelector((state) => state.unmanagedVm);
  const { filterQuery } = useAppSelector((state) => state.searchSort)

  const handleClearRebootFilter = () => {
    dispatch(resetSearchSort({ rebootSort: true }))
  }
  return (
    <Box
      sx={{
        ...style,
        justifyContent: 'space-between',
        mb: 3
      }}>
      <Box
        sx={{
          ...style
        }}>
        <Typography variant="h2" fontSize={20} lineHeight="28px" sx={{ minWidth: 30, ...style }}>
          {status === 'loading' ? <GlobalBarLoader sx={{ width: '20px' }} /> : unmanagedVmsData?.meta?.totalItems || 0} Instances
        </Typography>
        <Box
          sx={{
            width: '1px',
            height: '24px',
            background: '#454546'
          }}></Box>
        <Box sx={{
          ...style,
          gap: 2,
          alignItems: 'center'
        }}>
          <SortBox filterKey={filterKeys.reboot} />
          {filterQuery?.rebootQuery && (
            <Typography onClick={handleClearRebootFilter} variant="body1" fontSize={18} lineHeight="22px" sx={{ minWidth: 30, display: 'flex', gap: 0.5, cursor: 'pointer' }} color="primary">
              <CloseIcon color='primary' />
              Clear Filter
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...style
        }}>
        <SearchBox />
        <SortBox filterKey={filterKeys.sortUnvm} />
      </Box>
    </Box>
  );
};

export default SearchSortUmvm;
