import { ClusterListsResponse, ClusterListPayload, DescribeClusterResponse, DescribeClusterRequest, ListPvResponse, DeployYmlResponse, OnboardPvResponse, ListPvRequestPayload, DeployYmlCommandsRequestPayload, OnboardPvRequestPayload } from '../types';

import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';

export const getclusterListApi = (payload: ClusterListPayload): Promise<ApiResponse<ClusterListsResponse>> => {
  return ApiService.put<ClusterListsResponse>(`api/v1/unmanaged/listClusters`, payload);
};

export const getdescribeClusterApi = (
  payload: DescribeClusterRequest
): Promise<ApiResponse<DescribeClusterResponse>> => {
  return ApiService.put<DescribeClusterResponse>(`api/v1/describeCluster`, payload);
};

export const getListPvs = (payload: ListPvRequestPayload): Promise<ApiResponse<ListPvResponse>> => {
  return ApiService.put<ListPvResponse>(`/api/v1/unmanaged/listPvs`, payload);
};

export const getDeployYmlApi = (payload: DeployYmlCommandsRequestPayload): Promise<ApiResponse<DeployYmlResponse>> => {
  return ApiService.put<DeployYmlResponse>(`/api/v1/deployPVCommands`, payload);
};

export const getOnboardPvApi = (payload: OnboardPvRequestPayload): Promise<ApiResponse<OnboardPvResponse>> => {
  return ApiService.post<OnboardPvResponse>(`api/v1/onboardPv`, payload);
};
