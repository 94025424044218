import { useOktaAuth } from '@okta/okta-react';
import GlobalSpinner from 'components/GlobalSpinner';
import { getTenantPending } from 'features/auth/store';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import storage from 'utils/storage';

const SignInCallback: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { tenantData, inProcess } = useAppSelector((state) => state.tenants);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState?.accessToken !== undefined) {
      storage.setTokenExpire(String(authState.accessToken.expiresAt));
      storage.setAuthType('okta');
      storage.setToken(authState.accessToken?.accessToken);
      if (!tenantData) {
        dispatch(getTenantPending());
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (tenantData && inProcess === false) {
      if (storage.getMpToken()) {
        navigate(`/${storage.getMpType().toLowerCase()}-mp`);
      } else {
        if (
          storage.getIsNewUser() === true ||
          Object.keys(tenantData?.tenantInfoList)?.length === 0
        ) {
          navigate('/accounts');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, [tenantData]);

  if (inProcess) return <GlobalSpinner />;

  return <></>;
};

export default SignInCallback;
