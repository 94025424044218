import { createSlice } from '@reduxjs/toolkit';
// local import
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
import { ScalePolicySliceType } from '../types';

const initialState: ScalePolicySliceType = {
  allPolicies: [],
  inProcess: undefined,
  reload: false,
};

const scalerPoliciesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    getAllPolicies: (state, { payload }) => {
      state.inProcess = true;
    },
    getAllPoliciesFulfilled: (state, action) => {
      state.inProcess = false;
      state.allPolicies = action.payload;
    },
    getAllPoliciesRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'all-policy-error'});
    },

    setAttachPolicies: (state, { payload }) => {
      state.inProcess = true;
      state.reload = false
    },
    setAttachPoliciesFulfilled: (state, action) => {
      state.inProcess = false;
      state.reload = true
      toasts(<AlertComponenet msg={action.payload?.message} toastType={'success'} hideHeading/>, {toastId: 'attach-policies-success'});
    },
    setAttachPoliciessRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action?.payload} />, {toastId: 'attach-policies-error'});
    },

    setDetachPolicies: (state, { payload }) => {
      state.inProcess = true;
      state.reload = false
    },
    setDetachPoliciesFulfilled: (state, action) => {
      state.inProcess = false;
      state.reload = true
      toasts(<AlertComponenet msg={action.payload?.message} toastType={'success'} hideHeading />, {toastId: 'detach-policies-error'});
    },
    setDetachPoliciesRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action?.payload} />, {toastId: 'detach-policies-error'});
    }
  }
});

export const {
  getAllPolicies,
  getAllPoliciesFulfilled,
  getAllPoliciesRejected,
  setAttachPolicies,
  setAttachPoliciesFulfilled,
  setAttachPoliciessRejected,
  setDetachPolicies,
  setDetachPoliciesFulfilled,
  setDetachPoliciesRejected,
} = scalerPoliciesSlice.actions;

export default scalerPoliciesSlice.reducer;