import toast from 'react-hot-toast';
import { RequestFilter } from 'types/dateTenantFilter.model';
import storage from './storage';
import { Typography } from '@mui/material';

declare const window: Window &
  typeof globalThis & {
    msCrypto: any;
  };

export const ORIGIN = window.location.origin;
export const isUndefineNull = (payload: string | undefined | number | Date | null) => {
  return (
    payload === undefined ||
    !payload ||
    payload === 'undefined' ||
    payload === null ||
    payload === 'null'
  );
};

export const dateToISOtime = (date: string) => {
  const toIso = new Date(date).toISOString();
  const myDate = new Date(String(toIso));
  myDate.setDate(myDate.getDate() + 1);
  return new Date(myDate).toISOString();
};

export const checkTokenExpiry = () => {
  const expireTime = storage.getTokenExpire() * 1000;
  return (
    new Date().getTime() >= new Date(expireTime).setMinutes(new Date(expireTime).getMinutes() - 5)
  );
};

export function makeid() {
  let result = '';
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  result = String(array[0]);
  return result;
}

export const dateDiff = (start: Date, end: Date) => {
  const diffMs = +end - +start; // milliseconds between start & end
  const diffDays = Math.floor(diffMs / 86400000); // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.floor(diffMs / 1000 / 60);

  return diffMins;
};

export const minuteToHour = (min: any) => {
  if (min < 60) {
    return min + ' Minute';
  } else if (min == 60) {
    return 1 + ' Hour';
  } else {
    return min / 60 + ' Hour';
  }
};

export const awsRegions = [
  'us-east-2',
  'us-east-1',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-south-2',
  'eu-north-1',
  'eu-central-2',
  'me-south-1',
  'me-central-1',
  'sa-east-1',
  'us-gov-east-1',
  'us-gov-west-1'
];

export const GcpRegion = {
  'us-east1': ['us-east1-b', 'us-east1-c', 'us-east1-d'],
  'us-east4': ['us-east4-a', 'us-east4-b', 'us-east4-c'],
  'us-central1': ['us-central1-a', 'us-central1-b', 'us-central1-c', 'us-central1-f'],
  'us-west1': ['us-west1-a', 'us-west1-b', 'us-west1-c'],
  'europe-west4': ['europe-west4-a', 'europe-west4-b', 'europe-west4-c'],
  'europe-west1': ['europe-west1-b', 'europe-west1-c', 'europe-west1-d'],
  'europe-west3': ['europe-west3-a', 'europe-west3-b', 'europe-west3-c'],
  'asia-east1': ['asia-east1-a', 'asia-east1-b', 'asia-east1-c'],
  'asia-southeast1': ['asia-southeast1-a', 'asia-southeast1-b', 'asia-southeast1-c'],
  'asia-northeast1': ['asia-northeast1-a', 'asia-northeast1-b', 'asia-northeast1-c'],
  'asia-south1': ['asia-south1-a', 'asia-south1-b', 'asia-south1-c'],
  'australia-southeast1': [
    'australia-southeast1-a',
    'australia-southeast1-b',
    'australia-southeast1-c'
  ],
  'southamerica-east1': ['southamerica-east1-a', 'southamerica-east1-b', 'southamerica-east1-c'],
  'africa-south1': ['africa-south1-a', 'africa-south1-b', 'africa-south1-c'],
  'asia-east2': ['asia-east2-a', 'asia-east2-b', 'asia-east2-c'],
  'asia-northeast2': ['asia-northeast2-a', 'asia-northeast2-b', 'asia-northeast2-c'],
  'asia-northeast3': ['asia-northeast3-a', 'asia-northeast3-b', 'asia-northeast3-c'],
  'asia-south2': ['asia-south2-a', 'asia-south2-b', 'asia-south2-c'],
  'asia-southeast2': ['asia-southeast2-a', 'asia-southeast2-b', 'asia-southeast2-c'],
  'australia-southeast2': [
    'australia-southeast2-a',
    'australia-southeast2-b',
    'australia-southeast2-c'
  ],
  'europe-central2': ['europe-central2-a', 'europe-central2-b', 'europe-central2-c'],
  'europe-north1': ['europe-north1-a', 'europe-north1-b', 'europe-north1-c'],
  'europe-southwest1': ['europe-southwest1-a', 'europe-southwest1-b', 'europe-southwest1-c'],
  'europe-west10': ['europe-west10-a', 'europe-west10-b', 'europe-west10-c'],
  'europe-west12': ['europe-west12-a', 'europe-west12-b', 'europe-west12-c'],
  'europe-west6': ['europe-west6-a', 'europe-west6-b', 'europe-west6-c'],
  'europe-west8': ['europe-west8-a', 'europe-west8-b', 'europe-west8-c'],
  'europe-west9': ['europe-west9-a', 'europe-west9-b', 'europe-west9-c'],
  'me-central1': ['me-central1-a', 'me-central1-b', 'me-central1-c'],
  'me-central2': ['me-central2-a', 'me-central2-b', 'me-central2-c'],
  'me-west1': ['me-west1-a', 'me-west1-b', 'me-west1-c'],
  'northamerica-northeast1': [
    'northamerica-northeast1-a',
    'northamerica-northeast1-b',
    'northamerica-northeast1-c'
  ],
  'northamerica-northeast2': [
    'northamerica-northeast2-a',
    'northamerica-northeast2-b',
    'northamerica-northeast2-c'
  ],
  'southamerica-west1': ['southamerica-west1-a', 'southamerica-west1-b', 'southamerica-west1-c'],
  'us-east5': ['us-east5-a', 'us-east5-b', 'us-east5-c'],
  'us-south1': ['us-south1-a', 'us-south1-b', 'us-south1-c'],
  'us-west2': ['us-west2-a', 'us-west2-b', 'us-west2-c'],
  'us-west3': ['us-west3-a', 'us-west3-b', 'us-west3-c'],
  'us-west4': ['us-west4-a', 'us-west4-b', 'us-west4-c']
};

export const getSingleTenant = (array = false) => {
  if (storage.getTenant()) {
    if (array) {
      return Object.entries(storage.getTenant());
    } else {
      return storage.getTenant();
    }
  } else {
    return false;
  }
};

export const replaceString = (text: string | undefined) => {
  const regex = /[a-zA-Z ]/gi;
  if (text) {
    const number = text.replace(regex, '');
    if (number) {
      return Number(number);
    } else {
      return 0;
    }
  }
};

export const validateEmail = (values: any) => {
  const errors: { [k: string]: string } = {};
  if (!values.emailId) {
    errors.emailId = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.emailId)) {
    errors.emailId = 'Email address is invalid';
  }
  return errors;
};

export const isTokenAndAuthTypeValid = () => {
  if (isUndefineNull(storage.getAuthType())) {
    return false;
  } else {
    return true;
  }
};

export const toastErrorMsg = (payload: any, id?: string) => {
  if (payload) toast.error(String(payload), { id: id });
};

export const checkTenantLenghtInFilter = (requestFilter: RequestFilter) => {
  return requestFilter?.filters?.[6]?.value?.tenantNames?.length;
};

export const getConvertedLocalTime = (time: string | Date | number) => {
  if (!isUndefineNull(time)) {
    return new Date(time);
  }
};

export const epochToLocalDate = (ts: any) => {
  return new Date(ts * 1000);
};

export const localDateToEpoch = (d: Date) => {
  return (d.getTime() - d.getMilliseconds()) / 1000;
};

export const renderMultilineText = (text: string, textStyles: object) => {
  return text.split('\n').map((line: string, index: number) => (
    <Typography key={index} variant="body2" sx={textStyles}>
      {line}
    </Typography>
  ));
};