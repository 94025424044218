import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { GlobalLoadingSpinner} from 'components/GlobalSpinner';

interface ButtonProp extends ButtonProps {
  children?: React.ReactNode | string;
  text?: string;
  btnVarient?: 'contained' | 'outlined' | 'text';
  btnSize?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  onClick?: any;
  sx?: any;
  loading?: boolean;
}

const Buttons: FC<ButtonProp> = ({
  children,
  text,
  btnVarient = 'contained',
  btnSize = 'small',
  icon,
  onClick,
  sx,
  loading,
  ...props
}) => {
  return (
    <Button
      {...props}
      sx={sx}
      variant={btnVarient}
      size={btnSize}
      startIcon={icon}
      onClick={onClick}
    >
      {loading ? <GlobalLoadingSpinner height='10px'/> :  text ? text : children}
    </Button>
  );
};

export default Buttons;
