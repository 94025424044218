import { Pagination, styled } from '@mui/material';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '.MuiPaginationItem-root': {
    background: 'rgba(221, 255, 85, 0.08)',
    borderRadius: '12px',
    margin: '0 7px',
    width: '38px',
    height: '38px',
    fontSize: 18,
    color: theme.palette.primary.main,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'black',
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  '& .MuiPaginationItem-ellipsis': {
    backgroundColor: 'transparent'
  }
}));