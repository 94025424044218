import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery,takeLatest } from 'redux-saga/effects';
import { getUnManagedVmsApi, searchUnManagedVmsApi, getMultiplePartitionOnboardApi, getMirrorInProgressMetricsApis } from '../api';
// local import
import * as slice from '.';

export function* onGetUnManagedVms({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getUnManagedVmsApi(payload));
    yield put(slice.getUnManagedVmsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getUnManagedVmsRejected(e));
  }
}

export function* onSearchUnManagedVms({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => searchUnManagedVmsApi(payload));
    yield put(slice.getSearchUnManagedVmsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getSearchUnManagedVmsRejected({ e, userprops: payload }));
  }
}

export function* onGetMultiPartition({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getMultiplePartitionOnboardApi(payload));
    yield put(slice.getMultiPartitionsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getMultiPartitionsRejected(e));
  }
}

export function* onGetMirrorMetrics({ payload }: any): SagaIterator {
  try {
    const {data} = yield call(() => getMirrorInProgressMetricsApis(payload));
    yield put(slice.geMirrorInprogressMetricsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getMirrorInprogressMetricsRejected(e));
  } 
}

// Watcher Saga
export function* unManagedVmWatcherSaga(): SagaIterator {
  yield takeEvery('unmanagedvm/getUnManagedVms', onGetUnManagedVms);
  yield takeEvery('unmanagedvm/getSearchUnManagedVms', onSearchUnManagedVms);
  yield takeEvery('unmanagedvm/getMultiPartitions', onGetMultiPartition);
  //yield takeLatest('unmanagedvm/getMirrorInprogressMetrics', onGetMirrorMetrics);
}

export default unManagedVmWatcherSaga;
