import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, Button } from '@mui/material';
import { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
// local import
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import GlobalSpinner from 'components/GlobalSpinner';
import config from 'config';
import { getUnixTime } from 'date-fns';
import storage from 'utils/storage';
import azureInstrumentation from '../utils/instrumentation/azure'
import AzureLogo from '../assets/images/azure.svg';
import '../assets/styles.scss';
import { loginRequest } from '../config/azureConfig';

const AzureLogin: FC = () => {
  const azureAnalytics = azureInstrumentation.initialize()

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const state: string = queryParams.get('sso') || '';

  const initializeSignIn = async () => {
    azureAnalytics.dispatchRequest();

    storage.setAuthType('azure');
    instance.initialize();
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const account = instance.getAllAccounts();
          if (account.length === 0) {
            // No user signed in
            instance.loginRedirect({ scopes: loginRequest.scopes });
          }
        } else {
          if (tokenResponse.expiresOn) {
            storage.setTokenExpire(String(getUnixTime(new Date(tokenResponse.expiresOn))));
          }
          storage.setAuthType('azure');
          storage.setToken(tokenResponse.accessToken);
          azureAnalytics.authSuccess({ res: tokenResponse })
          navigate('/dashboard')
        }
      })
      .catch((err) => {
        azureAnalytics.authError({ error: err })
        console.error(err);
      });
  };

  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      storage.getAuthType() === 'azure' &&
      state !== 'true'
    ) {
      azureAnalytics.dispatchRequest();

      instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: accounts[0]
        })
        .then((res) => {
          if (res.expiresOn) storage.setTokenExpire(String(getUnixTime(new Date(res.expiresOn))));
          storage.setAuthType('azure');
          storage.setToken(res.accessToken);
          azureAnalytics.authSuccess({ res })
          navigate('/dashboard');
        })
        .catch(async (e) => {
          azureAnalytics.authError({ error: e })

          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: loginRequest.scopes,
              account: accounts[0]
            });
          } else {
            navigate('/signin');
          }
        });
    }
  }, [instance, isAuthenticated]);

  if (storage.getAuthType() === 'azure' && inProgress !== InteractionStatus.None)
    return <GlobalSpinner height="90px" />;

  return (
    <Box mt={3.5} className="azue_button">
      {config.enableMicrosoftLogin && (
        <Button
          onClick={initializeSignIn}
          sx={{
            px: 4,
            width: '100%',
            height: '56px',
            background: '#fff',
            color: '#161718',
            fontSize: '16px',
            gap: 2,
            ':hover': {
              backgroundColor: '#fff'
            }
          }}>
          <img src={AzureLogo} alt=" AzureLogo" />
          Microsoft Sign in
        </Button>
      )}
    </Box>
  );
};

export default AzureLogin;
