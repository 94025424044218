import { Box, Typography } from '@mui/material';
import Buttons from 'components/buttons';
import format from 'date-fns-tz/format';
import { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { CancelInstancesType } from '../../types/types';

const ConfirmCancelOnboarding: FC<CancelInstancesType> = (props) => {
  const { handleCancelOnboard, handleModalClose } = props;
  const { bulkOnboardSchedulePayload, scheduleModalPropsList, bulkSchedule } = useAppSelector(
    (state) => state.unmanagedVm
  );

  const getOnboardingDate = () => {
    if(bulkOnboardSchedulePayload) {
      if(bulkOnboardSchedulePayload?.scheduledStartTime) {
        return format(new Date(bulkOnboardSchedulePayload?.scheduledStartTime), "MMM dd yyyy 'at' p")
      } else if(bulkOnboardSchedulePayload?.rebootStartTime) {
        return format(new Date(bulkOnboardSchedulePayload?.rebootStartTime), "MMM dd yyyy 'at' p")
      }
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={0}>
          Cancel Onboarding ({scheduleModalPropsList?.scheduleInstanceInfo?.length || 0})
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="gray"
          fontSize={16}
          lineHeight="28px"
          mb={0}>
          Onboarding on: {getOnboardingDate()}
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom fontSize={14} lineHeight="20px" mb={0.5} mt={3}>
        Are you sure you want to cancel onboarding?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
        <Buttons
          sx={{
            color: '#ddff55'
          }}
          btnVarient="text"
          onClick={handleModalClose}>
          Cancel
        </Buttons>
        <Buttons sx={{minWidth: '200px'}} text="Cancel Onboarding" onClick={handleCancelOnboard} loading={bulkSchedule?.status === 'loading'}/>
      </Box>
    </Box>
  );
};

export default ConfirmCancelOnboarding;
