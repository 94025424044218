import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sortFilter } from '../store/searchSort.slice';
import { filterDropdownList } from 'constants/filter';
import { FilterOption } from 'types/common';

const useSort = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { filterQuery } = useAppSelector((state) => state.searchSort);
  const openSortList = Boolean(anchorEl);
  const [selectedValue, setSelectedValue] = useState<any>('');
  const dispatch = useAppDispatch();

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const getSortKey = (value: string, filterType: keyof FilterOption) => {
    const sortFilters = filterDropdownList[`${filterType}`];
    for (let i = 0; i < sortFilters?.length; i++) {
      const key = Object.keys(sortFilters[i])[0];
      if (sortFilters[i][key] === value) {
        dispatch(sortFilter({ [`${filterType}Query`]: key }));
        break;
      }
    }
  };
  
  const handleSort = (
    event: React.MouseEvent<HTMLElement>,
    sortValue: string,
    filterType: keyof FilterOption
  ) => {

    getSortKey(sortValue, filterType);
    setSelectedValue({ [filterType]: sortValue });
    handleSortMenuClose();
  };

  const handleSortMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (!filterQuery?.sortUnvmQuery && !filterQuery?.sortQuery && !filterQuery?.rebootQuery) {
      setSelectedValue('');
    }
    else if (!filterQuery?.rebootQuery) {
      const { ['reboot']: tmp, ...rest } = selectedValue;
      setSelectedValue(rest);
    }
  }, [filterQuery]);

  return {
    anchorEl,
    openSortList,
    setAnchorEl,
    handleSortMenuClose,
    handleSortMenuOpen,
    handleSort,
    selectedValue
  };
};

export default useSort;
