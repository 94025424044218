import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getFilesListApi } from '../api';
// local import
import { fileListFulfilled, fileListRejected } from '.';

export function* onGetFileList({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getFilesListApi(payload));
    yield put(fileListFulfilled(data));
  } catch (e: any) {
    yield put(fileListRejected(e));
  }
}

// Watcher Saga
export function* getFileListWatcherSaga(): SagaIterator {
  yield takeEvery('getfilelist/getFileList', onGetFileList);
}

export default getFileListWatcherSaga;
