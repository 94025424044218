import * as slice from '.';
import * as api from '../components/shared/api';
import { getclusterListApi, getDeployYmlApi, getdescribeClusterApi, getListPvs, getOnboardPvApi } from '../components/unmanagedVmKubernetes/api';
import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';

export function* onGetKubernetesSaving({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.getKubernetesSaving(payload));
    yield put(slice.getKubernetesSavingFulfilled(data));
  } catch (e: any) {
    yield put(slice.getKubernetesSavingRejected(e));
  }
}
export function* onGetSimplifyPvCreation(): SagaIterator {
  try {
    const data = yield call(() => api.getSimplifyPvCreation());
    yield put(slice.getSimplifyPvCreationFulfilled(data));
  } catch (e: any) {
    yield put(slice.getSimplifyPvCreationRejected(e));
  }
}
export function* onGetclusterList({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getclusterListApi(payload));
    yield put(slice.getClusterListFulfilled(data));
  } catch (e: any) {
    yield put(slice.getClusterListRejected(e));
  }
}

export function* onGetDescribeCluster({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getdescribeClusterApi(payload));
    yield put(slice.getdescribeClusterFulfilled(data));
  } catch (e: any) {
    yield put(slice.getdescribeClusterRejected(e));
  }
}

export function* onGetListPvs({payload}:any):SagaIterator{
  try{
    const data = yield call(()=>getListPvs(payload));
    yield put(slice.getListPvFulfilled(data));
  } catch(e:any){
    yield put(slice.getListPvRejected(e));
  }
}

export function* getOnboardPv({payload}:any):SagaIterator{
  try{
    const data = yield call(()=>getOnboardPvApi(payload));
    yield put(slice.getOnboardPvFulfilled(data));
  } catch(e:any){
    yield put(slice.getOnboardPvRejected(e));
  }
}


export function* getDeployYmlcommands({payload}:any):SagaIterator{
  try{
    const data = yield call(()=>getDeployYmlApi(payload));
    yield put(slice.getDeployYmlFulfilled(data));
  } catch(e:any){
    yield put(slice.getDeployYmlRejected(e));
  }
}

// Watcher Saga
export function* kubernetesWatcherSaga(): SagaIterator {
  yield takeEvery('kubernetes/getKubernetesSaving', onGetKubernetesSaving);
  yield takeEvery('kubernetes/getSimplifyPvCreation', onGetSimplifyPvCreation);
  yield takeEvery('kubernetes/getClusterList', onGetclusterList);
  yield takeEvery('kubernetes/getdescribeCluster', onGetDescribeCluster);
  yield takeEvery('kubernetes/getListPv', onGetListPvs);
  yield takeEvery('kubernetes/getOnboard', getOnboardPv);
  yield takeEvery('kubernetes/getDeployYml', getDeployYmlcommands);
}

export default kubernetesWatcherSaga;
