import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import FTUInstrumentation from '../../utils/instrumentation';
import GlobalSpinner from 'components/GlobalSpinner';
import CustomToolTip from 'components/StylesTooltip';
import Buttons from 'components/buttons';
import { setSetUpRes } from 'features/accounts/store/accounts.slice';
import { PermissionsKeys } from 'features/accounts/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CloudProviderCaps } from 'types/common';
import { isUndefineNull } from 'utils/common';
import storage from 'utils/storage';
import { toast as toasts } from 'react-toastify';
import { cloudEntityOnboardApi, listCloudEntitiesApi } from '../api';
import {
  CloudEntityDetails,
  CloudEntitySetupRequest,
  GetCloudEntityDetails,
  ListCloudEntitiesRequest
} from '../types';
import { isGcpOrAzureAccount, alias, maxAccountsAllowed } from '../../accountHome/utils/helper';
import Info from '../assets/info.svg';
import AlertComponenet from 'components/common/AlertComponents';
import { Stepper } from './Stepper';
import { stepperData } from '../../utils/helper';

const ListCloudEntities = () => {
  let initCount: any;
  const ftuInstrumentatio = new FTUInstrumentation();
  const { inProcess } = useAppSelector((state) => state.tenants);
  const { cloudType } = useAppSelector((state) => state.accounts);
  const [loading, setLoading] = useState<boolean>(false);
  const [exitfriendlyName, setExitFriendlyName] = useState<string>('');
  const [selectedSubId, setSelectedSubId] = useState<string[]>([]);
  const [subList, setSubList] = useState<GetCloudEntityDetails>([]);
  const [timer, setTimer] = useState<number>(maxAccountsAllowed(cloudType));
  const [starttimer, setStartTimer] = useState<boolean>(false);
  const permissions = storage.getAccountsPermission();
  const [_, setSearchParams] = useSearchParams();


  const isAwsAccountsExhausted =
    !isGcpOrAzureAccount(cloudType) && selectedSubId.length > maxAccountsAllowed(cloudType);
  const isGcpAndAzureAccountsExhausted =
    isGcpOrAzureAccount(cloudType) && selectedSubId.length > maxAccountsAllowed(cloudType);

  // list subscriptions api
  const getSubscriptionList = async () => {
    let payload: ListCloudEntitiesRequest;
    if (
      (!isUndefineNull(storage.getGraphToken()) &&
        !isUndefineNull(storage.getManagentToken()) &&
        cloudType === 'AZURE') ||
      !isUndefineNull(storage.getGCPToken() && cloudType === 'GCP')
    ) {
      const productList: string[] = [];
      if (permissions) {
        productList.push(
          ...Object.keys(permissions).filter((i: string) => permissions[i as PermissionsKeys])
        );
      }

      payload = {
        ...(cloudType === 'AZURE' && {
          azureGraphToken: storage.getGraphToken(),
          azureManagementToken: storage.getManagentToken()
        }),
        ...(cloudType === 'GCP' && { gcpAccessToken: storage.getGCPToken() }),
        productList: productList,
        cloudProvider: cloudType as CloudProviderCaps
      };
      listCloudEntitiesApi(payload)
        .then((res: any) => {
          selectAllSubs(res?.data?.cloudEntityDetails);
          setSubList(res?.data?.cloudEntityDetails);
          setExitFriendlyName(res?.data?.dashboardFriendlyName);
          setLoading(false);
        })
        .catch((e: any) => {
          toast.error(`Faild to get ${cloudType === 'AZURE' ? 'subscriptions' : 'projects'}`, {
            id: 'sub-list'
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    getSubscriptionList();
  }, [storage.getGraphToken(), storage.getManagentToken(), storage.getGCPToken()]);

  const selectAllSubs = (data: GetCloudEntityDetails) => {
    const temp: string[] = [];
    data?.map((itm: CloudEntityDetails) => {
      if (itm.lucidityManageable === false && temp.length < 5) temp.push(itm?.cloudEntityId);
    });
    setSelectedSubId(temp);
  };

  const handleCheckSubIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subId = event.target.name;
    if (selectedSubId.includes(subId)) {
      setSelectedSubId((prev) => prev?.filter((s) => !s?.includes(subId)));
    } else {
      setSelectedSubId((prev) => [...prev, subId]);
    }
  };

  const handleClearAll = () => {
    setSelectedSubId([]);
  };

  // handle setup api
  const handleSubmit = () => {
    if (isAwsAccountsExhausted || isGcpAndAzureAccountsExhausted) {
      toast(
        `Sorry, you can only select up to ${maxAccountsAllowed(cloudType)} ${alias(cloudType)} 
        at once. Please adjust your selection. Thanks`
      );
      return;
    }
    if (
      (cloudType === 'AZURE' && storage.getGraphToken() && storage.getManagentToken()) ||
      (cloudType === 'GCP' && storage.getGCPToken())
    ) {
      const productList: string[] = [];
      setLoading(true);
      setStartTimer(true);
      if (permissions) {
        productList.push(
          ...Object.keys(permissions).filter((i: string) => permissions[i as PermissionsKeys])
        );
      }
      const cloudEntityIdNameObj: { entityId: string; entityName: string; }[] = []
      subList.forEach((entity) => {
        if(selectedSubId.includes(entity.cloudEntityId)){
          cloudEntityIdNameObj.push({
            entityId: entity.cloudEntityId,
            entityName: entity.cloudEntityName
          })
        }
      })
      const payload: CloudEntitySetupRequest = {
        cloudEntityIds: cloudEntityIdNameObj,
        azureGraphToken: storage.getGraphToken(),
        azureManagementToken: storage.getManagentToken(),
        productList: productList,
        dashboardFriendlyName: exitfriendlyName,
        cloudProvider: cloudType as CloudProviderCaps,
        gcpAccessToken: storage.getGCPToken()
      };

      ftuInstrumentatio.onboardingScheduled(
        'ftu_onboarding_request',
        JSON.stringify({
          cloudEntityIds: selectedSubId,
          productList: productList
        }),
        cloudType
      );
      cloudEntityOnboardApi(payload)
        .then((res) => {
          ftuInstrumentatio.onboardingScheduled(
            'ftu_onboarding_response',
            JSON.stringify(res.data),
            cloudType
          );
          clearInterval(initCount);
          setSearchParams({trackProgress: 'true'})
        })
        .catch((e: any) => {
          toasts(<AlertComponenet />, { toastId: 'cloud-entity-onboard-error' });
          ftuInstrumentatio.onboardingFailure(JSON.stringify(e), cloudType);
          clearInterval(initCount);
        });
    }
  };

  useEffect(() => {
    if (starttimer === true) {
      initCount = setInterval(() => {
        if (timer > 0) setTimer(timer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(initCount);
    };
  }, [timer, starttimer]);

  return (
    <>
    <Grid container spacing={2} marginTop={0.5} maxWidth={'904px'} sx={{ mx: 'auto' }}>
      <Stepper stepsData={cloudType === 'AZURE' ?  stepperData.azureSteps : stepperData.gcpSteps} step={2}  />
      <Grid item xs={12} marginTop={2}>
        <Box sx={{ p: 3.1, background: 'rgba(255, 255, 255, 0.05)', borderRadius: '16px' }}>
          <Typography variant="h3" gutterBottom fontSize={20} lineHeight="28px" mb={0.5}>
            {cloudType === 'AZURE' && 'Azure Tenant Name'}
            {cloudType === 'GCP' && 'Organization'}
          </Typography>
          <Typography variant="body1" gutterBottom fontSize={14} lineHeight="28px" mb={0}>
            {cloudType === 'AZURE' &&
              'Please provide a friendly name to identify your Azure tenant on the dashboard.'}
            {cloudType === 'GCP' &&
              'This will be used to identify your organization on the main dashboard. '}
          </Typography>
          <TextField
            data-testid="friendly-name"
            value={exitfriendlyName}
            disabled={true}
            sx={{
              background: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              width: 523,
              mt: 2,
              mb: 3,
              '& .MuiInputBase-input': {
                height: 40,
                padding: '0 16px',
                color: 'rgba(255, 255, 255, 0.5)'
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none'
                }
              }
            }}
          />
          <Divider light sx={{ borderColor: '#454546', width: '106%', ml: -3.1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" gutterBottom fontSize={20} lineHeight="28px" mb={0.5} mt={3}>
              {cloudType === 'AZURE' && 'Select Azure subscription(s)'}
              {cloudType === 'GCP' && 'Select projects'}
            </Typography>
            <Typography
              variant="body2"
              fontSize={14}
              lineHeight="20px"
              mb={0.5}
              mt={3}
              color="primary">
              {`${selectedSubId.length}/${subList.length} ${alias(cloudType)} selected`}
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom fontSize={14} lineHeight="28px" mb={0}>
            {cloudType === 'AZURE' &&
              'Select the Azure subscription(s) for which you would like to grant permissions.'}
            {cloudType === 'GCP' &&
              'Select the projects(s) for which you would like to grant permissions.'}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Buttons
              disabled={selectedSubId?.length === 0}
              text="Clear all"
              btnVarient="text"
              sx={{ fontSize: 12, pl: 0, color: '#DDFF55', '&:hover': { background: 'none' } }}
              onClick={handleClearAll}
            />
          </Box>
          <Divider light sx={{ borderColor: '#454546', mt: 0.5, mb: 2.5 }} />
          <Box
            sx={{
              maxHeight: '27vh',
              overflowX: 'hidden',
              msOverflowY: 'scroll',
              px: 1.4
            }}>
            {loading || inProcess ? (
              <GlobalSpinner height="140px" />
            ) : (
              <>
                {subList?.map((itm: CloudEntityDetails) => (
                  <Box
                    key={itm?.cloudEntityId}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pr: 1,
                      position: 'relative',
                      height: '40px'
                    }}>
                    <CustomToolTip
                      showIcon={false}
                      left={0}
                      right={'auto'}
                      top={1}
                      text={`You can select upto ${maxAccountsAllowed(cloudType)} ${alias(
                        cloudType
                      )}`}
                      disable={
                        selectedSubId.includes(itm?.cloudEntityId) ||
                        selectedSubId?.length !== maxAccountsAllowed(cloudType)
                      }>
                      <FormControlLabel
                        data-testid="select-subid"
                        sx={{
                          display: 'block',
                          '& .MuiTypography-root': {
                            textAlign: 'end'
                          }
                        }}
                        control={
                          <Checkbox
                            name={itm?.cloudEntityId}
                            disabled={
                              itm.lucidityManageable ||
                              (!selectedSubId.includes(itm?.cloudEntityId) &&
                                selectedSubId?.length === maxAccountsAllowed(cloudType))
                            }
                            checked={
                              itm.lucidityManageable || selectedSubId.includes(itm?.cloudEntityId)
                            }
                            onChange={handleCheckSubIdChange}
                          />
                        }
                        label={cloudType === 'GCP' ? itm?.cloudEntityId : itm?.cloudEntityName}
                      />
                    </CustomToolTip>
                    <Typography
                      variant="body1"
                      gutterBottom
                      fontSize={16}
                      lineHeight="24px"
                      mb={0}
                      color="rgba(255, 255, 255, 0.50)"
                      textAlign="end"
                      width="100%">
                      {cloudType === 'GCP' ? itm?.cloudEntityNumber : itm?.cloudEntityId}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
        {timer === 0 && loading && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              background: 'rgba(181, 209, 70, 0.08)',
              border: '2px solid rgba(221, 255, 85, 0.08)',
              borderRadius: '8px',
              p: '8px 15px',
              mt: 2
            }}>
            <img src={Info} alt="Info" />
            <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px" mb={0}>
              Please wait while your account is being linked.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
    <Box
        sx={{
          display: 'flex',
          gap: '2px',
          width: '100%',
          left: 0,
          bottom: 2,
          position: 'fixed',
          justifyContent: 'flex-end',
          background: 'rgb(35, 36, 38)',
          padding: '15px'
          
        }}>
        {timer !== 0 && (
          <Buttons
            loading={loading}
            disabled={selectedSubId?.length !== 0 ? false : true}
            btnVarient="contained"
            text="Submit"
            onClick={handleSubmit}
            sx={{ width: 100, mr: 2 }}
          />
        )}
      </Box>
    </>
  );
};

export default ListCloudEntities;
