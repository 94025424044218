import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { FC } from 'react';
import { MountPointInfos } from '../../../../types';
import { VolumeDetailsType } from '../../../../types/types';

const VolumeDetails: FC<VolumeDetailsType> = ({ mountpointInfoList }) => {
  return (
    <Table size="medium" sx={{ backgroundColor: '#161718' }}>
      <TableHead>
        <TableRow>
          <TableCell>Volumes</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Volume Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mountpointInfoList?.map((mountpoint: MountPointInfos, index: number) => (
          <TableRow key={index}>
            <TableCell>{mountpoint?.mountPoint}</TableCell>
            <TableCell>{mountpoint?.size + 'GiB'}</TableCell>
            <TableCell>{mountpoint?.partitionScheduleStatus}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default VolumeDetails;
