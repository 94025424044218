import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  UserListResType,
  CreateUserResType,
  UsersType,
  RoleListResponse,
  UpdateUserRoleReq,
  UpdateUserRoleRes,
} from '../types';

export const getUserListApi = (): Promise<ApiResponse<UserListResType>> =>
  ApiService.get(`/api/v1/authorizations/user/list`);

export const getSingleUserDetailApi = (payload: string): Promise<ApiResponse<RoleListResponse>> =>
  ApiService.get(`/api/v1/authorizations/user?userEmail=${payload}`);

export const createUserApi = (payload: CreateUserResType): Promise<ApiResponse<UsersType>> =>
  ApiService.post(`/api/v1/authorizations/user/create`, payload);

export const getRoleListApi = (): Promise<ApiResponse<RoleListResponse>> =>
  ApiService.get(`/api/v1/authorizations/role/list`);

export const getRoleDescribeApi = (): Promise<ApiResponse<any>> =>
  ApiService.get(`/api/v1/authorizations/role/describe`);

export const updateUserRoleApi = (
  payload: UpdateUserRoleReq
): Promise<ApiResponse<UpdateUserRoleRes>> =>
  ApiService.post(`/api/v1/authorizations/user/updateRoles`, payload);

export const resetPasswordApi = (payload: string): Promise<ApiResponse<any>> =>
  ApiService.get(`/api/v1/authorizations/user/reactivate?userEmail=${payload}`);