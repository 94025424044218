import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  ManagedVmsResponse,
  ManagedInstancerMetricsResponse,
  ScalarMetricsResponse,
  GetAllPolicies,
  AttachDetachReqRes,
  OperationTimelineRes,
  OperationTimelineReq
} from '../types/index';

export const getScalarMetricsApis = (payload: any): Promise<ApiResponse<ScalarMetricsResponse>> =>
  ApiService.get(`/api/v2/virtual/metrics?id=${payload.instanceId}&filters=${payload?.filter}`);

export const getManagedVmsApi = (payload: any): Promise<ApiResponse<ManagedVmsResponse>> =>
  ApiService.put<ManagedVmsResponse>(`/api/v4/virtual/managed-vm`, payload.filter, {
    params: { 
      ...(payload.sort ? { sort: payload.sort } : {}),
      ...(payload.searchQuery ? { query: payload.searchQuery } : {})
     }
  });

export const getOperationalTimelineApi = (
  payload: OperationTimelineReq
): Promise<ApiResponse<OperationTimelineRes>> =>
  ApiService.put<OperationTimelineRes>(`/api/v1/instance/operation-timeline`, payload);

export const getScalarMetricsApi = (
  payload: any
): Promise<ApiResponse<ManagedInstancerMetricsResponse>> =>
  ApiService.get(`/api/v2/virtual/metrics?`, {
    params: {
      tenantId: payload.tenantId,
      instanceId: payload?.instanceId,
      diskpoolId: payload.diskpoolId,
      ...(payload.duration ? { duration: payload.duration } : {}),
      ...(payload.startTime ? { startTime: payload.startTime } : {}),
      ...(payload.endTime ? { endTime: payload.endTime } : {})
    }
  });

// policies
export const getAllpoliciesApi = (payload: any): Promise<ApiResponse<GetAllPolicies>> =>
  ApiService.get(`/api/v1/scale-policy?accountId=${payload}`);

export const attachpoliciesApi = (
  payload: AttachDetachReqRes
): Promise<ApiResponse<AttachDetachReqRes>> =>
  ApiService.put<AttachDetachReqRes>(`/api/v1/scale-policy/attach`, payload);

export const detachpoliciesApi = (
  payload: AttachDetachReqRes
): Promise<ApiResponse<AttachDetachReqRes>> =>
  ApiService.put<AttachDetachReqRes>(`/api/v1/scale-policy/detach`, payload);
