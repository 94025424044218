import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { SummaryPayload, SummaryResponse } from '../types';

export const getAuditSummaryApi = (
  payload: SummaryPayload
): Promise<ApiResponse<SummaryResponse>> =>
  ApiService.get<SummaryResponse>(
    `/api/v1/audit/summary?fileName=${payload?.fileName}&clientName=${payload?.clientName}`
  );

  export const getAuditSummarySheet = (
    payload: SummaryPayload
  ): Promise<ApiResponse<SummaryResponse>> =>
    ApiService.get<SummaryResponse>(
      `/api/v1/audit/summary-sheet/download?fileName=${payload?.fileName}&clientName=${payload?.clientName}`
    );