import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getAllpoliciesApi, attachpoliciesApi, detachpoliciesApi} from '../api';
// lo
import {
  getAllPoliciesFulfilled,
  getAllPoliciesRejected,
  setAttachPoliciesFulfilled,
  setAttachPoliciessRejected,
  setDetachPoliciesFulfilled,
  setDetachPoliciesRejected
} from '.';

export function* onGetAllPolicies({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getAllpoliciesApi(payload));
    yield put(getAllPoliciesFulfilled(data));
  } catch (e: any) {
    yield put(getAllPoliciesRejected(e));
  }
}

export function* onSetAttachPolicies({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => attachpoliciesApi(payload));
    yield put(setAttachPoliciesFulfilled(data));
  } catch (e: any) {
    yield put(setAttachPoliciessRejected(e));
  }
}
export function* onSetDetachPolicies({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => detachpoliciesApi(payload));
    yield put(setDetachPoliciesFulfilled(data));
  } catch (e: any) {
    yield put(setDetachPoliciesRejected(e));
  }
}

// Watcher Saga
export function* scalerPoliciesWatcherSaga(): SagaIterator {
  yield takeEvery('policies/getAllPolicies', onGetAllPolicies);
  yield takeEvery('policies/setAttachPolicies', onSetAttachPolicies);
  yield takeEvery('policies/setDetachPolicies', onSetDetachPolicies);
}

export default scalerPoliciesWatcherSaga;
