import { Box, Divider, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import '../assets/style.scss';

export const ProgressContainer = () => {
  const { cloudEntityStatusNumbers } = useAppSelector((state) => state.accounts);
  const successPercentage =
    ((cloudEntityStatusNumbers?.successEntity || 0) /
      (cloudEntityStatusNumbers?.totalEntity || 0)) *
    100;
  const failurePercentage =
    ((cloudEntityStatusNumbers?.noOfFailueEntity || 0) /
      (cloudEntityStatusNumbers?.totalEntity || 0)) *
    100;
  const inProgressPercentage = 100 - (successPercentage + failurePercentage);

  return (
    <Box
      sx={{
        bgcolor: '#2e2e2e',
        borderRadius: '16px',
        width: 300,
        color: '#ffffff',
        maxHeight: '300px',
        mt: 1
      }}>
      <Box p={2} sx={{ borderBottom: '1px solid #565656' }}>
        <Typography variant="h6">Account Integration Progress</Typography>
        <Box mt={1} mb={2} width="100%">
          <Box display="flex" sx={{ width: '100%' }}>
            <Box
              className={`progress-bar ${successPercentage > 0 ? 'first-visible' : ''} ${
                failurePercentage === 0 && inProgressPercentage === 0 ? 'last-visible' : ''
              }`}
              sx={{ width: `${successPercentage || 0}%`, height: '10px', bgcolor: '#DDFF55' }}
            />
            <Box
              className={`progress-bar ${
                failurePercentage > 0 && successPercentage === 0 ? 'first-visible' : ''
              } ${inProgressPercentage === 0 ? 'last-visible' : ''}`}
              sx={{
                width: `${failurePercentage || 0}%`,
                height: '10px',
                bgcolor: 'rgb(224, 92, 92)'
              }}
            />
            <Box
              className={`progress-bar ${inProgressPercentage > 0 ? 'last-visible' : ''}`}
              sx={{
                width: `${inProgressPercentage || 0}%`,
                height: '10px',
                bgcolor: 'rgb(47, 55, 38)'
              }}
            />
          </Box>
          <Typography variant="body2" mt={1}>
            {cloudEntityStatusNumbers?.completionPercentage}% completed
          </Typography>
        </Box>
      </Box>
      <Grid container direction="column" spacing={2} p={2}>
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  bgcolor: '#cddc39',
                  width: 16,
                  height: 16,
                  borderRadius: 1,
                  mr: 1
                }}
              />
              <Typography>Success</Typography>
            </Box>
            <Typography>{cloudEntityStatusNumbers?.successEntity}</Typography>
          </Box>
        </Grid>
        <Divider sx={{ bgcolor: '#565656', my: 1, ml: 1 }} />
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  bgcolor: '#f44336',
                  width: 16,
                  height: 16,
                  borderRadius: 1,
                  mr: 1
                }}
              />
              <Typography>Failed</Typography>
            </Box>
            <Typography>{cloudEntityStatusNumbers?.noOfFailueEntity}</Typography>
          </Box>
        </Grid>
        <Divider sx={{ bgcolor: '#565656', my: 1, ml: 1 }} />
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  bgcolor: '#424242',
                  width: 16,
                  height: 16,
                  borderRadius: 1,
                  mr: 1
                }}
              />
              <Typography>In progress</Typography>
            </Box>
            <Typography>{cloudEntityStatusNumbers?.inprogressEntity}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
