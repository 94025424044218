import { AuthenticationResult } from "@azure/msal-browser";
import AuthInstrumentation from "../../../../utils/instrumentation";

class AzureAuthInstrumentation extends AuthInstrumentation {
  static authProvider: string
  static eventName = 'sign_in'

  constructor() {
    super(AzureAuthInstrumentation.eventName)

    AzureAuthInstrumentation.authProvider = 'azure'
  }

  dispatchRequest = () => {
    this.pushEvent({
      auth_provider: AzureAuthInstrumentation.authProvider,
      type: 'request'
    })
  }

  authSuccess = ({ res }: { res: AuthenticationResult }) => {
    const userInfo = res.account

    this.pushEvent({
      type: 'response',
      status: 'success',
      auth_provider: AzureAuthInstrumentation.authProvider,
      user_email: userInfo?.username
    })
  }

  authError = ({ error }: { error: any }) => {
    this.pushEvent({
      type: 'response',
      status: 'failed',
      status_detail: `${JSON.stringify(error)}`,
      auth_provider: AzureAuthInstrumentation.authProvider,
    })
  } 
}

function initialize() {
  return new AzureAuthInstrumentation();
}

export default {
  initialize
};