import Instrumentation from "utils/instrumentation";

class FTUInstrumentation extends Instrumentation {
  constructor() {
    super('ftu_onboarding')
  }

  onboardingScheduled(stage: string, payload: any, cloud:string) {
    this.pushEvent({
      ftu_onboarding_stage: stage,
      cloud_type: cloud,
      status: 'success',
      status_detail: payload,
    })
  }

  onboardingFailure(error: any, cloud: string) {
    this.pushEvent({
      ftu_onboarding_stage: 'ftu_onboarding_response',
      status: 'error',
      cloud_type: cloud,
      status_detail: `${error}`,
    })
  }

  onboardingOnboardStatus(status: any, cloud: string) {
    this.pushEvent({
      account_onboard_status: status,
      cloud_type: cloud
    })
  }
}

export default FTUInstrumentation;