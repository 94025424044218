import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
// local import
import { makeid, toastErrorMsg } from 'utils/common';
import { ScheduleRequestSliceType } from '../types';

const initialState: ScheduleRequestSliceType = {
  scheduleRequest: {
    tenantId: '',
    instanceId: '',
    scheduledStartTime: '',
    scheduledEndTime: '',
    mountPoint: '',
    size: 0
  },
  multiParitionScheduleRequest: {
    tenantId: '',
    instanceId: '',
    scheduledStartTime: '',
    scheduledEndTime: '',
    mountPointList: [],
    osType: ''
  },
  successMsg: '',
  cleanupScheduleMsg:'',
  inProcess: undefined,
  inProcessSS: undefined,
  error: undefined,
  scalerSavings: undefined,
  triggerCleanUp: false,
  cleanupInit: {
    isMultiPartitionOnboard: false,
    isCleanup: true,
    cleanupType: undefined,
    cleanupResponseType: undefined,
    cleanupScheduleData: undefined,
    customCleanupDate: undefined
  }
};

const scheduleSlice = createSlice({
  name: 'schedulerequest',
  initialState,
  reducers: {
    setScheduleRequest: (state, action) => {
      Object.assign(state.scheduleRequest, action.payload);
    },

    setMultiPartitionScheduleRequest: (state, action) => {
      Object.assign(state.multiParitionScheduleRequest, action.payload);
    },

    createScheduleSlice: (state, { payload }) => {
      state.inProcess = true;
    },
    createScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toast.success(action.payload, { id: 'schedule-success' });
      state.successMsg = 'create' + makeid();
    },
    createScheduleRejected: (state, action) => {
      state.inProcess = false;
      if (action.payload) { toast.error(action.payload, { id: 'schedule-error', duration: 6500 });}
      state.successMsg = 'create-reject' + makeid();
    },

    updateSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    updateScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toast.success(String(action.payload), { id: 'schedule-success' });
      state.successMsg = 'update' + makeid();
    },
    updateScheduleRejected: (state, action) => {
      state.inProcess = false;
      toastErrorMsg(action.payload, 'update-schedule-error');
    },

    cancleSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    cancleScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toast.success(action.payload, { id: 'schedule-success' });
      state.successMsg = 'cancel' + makeid();
    },
    cancleScheduleRejected: (state, action) => {
      state.inProcess = false;
      toastErrorMsg(action.payload, 'cancel-schedule-error');
    },

    getScalerSavings: (state, { payload }) => {
      state.inProcessSS = true;
    },
    getScalerSavingsFulfilled: (state, action) => {
      state.scalerSavings = action.payload;
      state.inProcessSS = false;
    },
    getScalerSavingsRejected: (state, action) => {
      state.inProcessSS = false;
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'scaler-saving-error' });
    },

    setTriggerCleanupApi: (state, action) => {
      state.triggerCleanUp = action.payload;
    },
    resetTriggerCleanupApi: (state) => {
      state.triggerCleanUp = false;
    },

    createCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    createCleanupScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = true;
    },
    createCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
    },

    updateCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    updateCleanupScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = true;
    },
    updateCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
      state.cleanupScheduleMsg = {error: action.payload}
    },

    cancleCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    cancleCleanupScheduleFulfilled: (state) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = false;
    },
    cancleCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
    },

    setCleanupInit: (state, action) => {
      Object.assign(state.cleanupInit, action.payload);
    },
    resetCleanupInit: (state) => {
      state.cleanupInit.isCleanup = true;
      state.cleanupInit.cleanupType = undefined;
      state.cleanupInit.customCleanupDate = undefined;
    },
    resetSchedule: (state, action) => {
      Object.assign(state, action.payload);
    },

    /**
     *
     * multi partition
     */
    createMultiPartScheduleSlice: (state, { payload }) => {
      state.inProcess = true;
    },
    createMultiPartScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action.payload} hideHeading={true} toastType="onboard" />, {
        toastId: 'mult-partition-fullfiled-success'
      });
      state.successMsg = 'create' + makeid();
    },
    createMultiPartScheduleRejected: (state, action) => {
      state.inProcess = false;
      if (action.payload) {
        toasts(<AlertComponenet msg={action.payload} hideHeading={true}/>, {
          toastId: 'mult-partition-fullfiled-error'
        });
      }
      state.successMsg = 'multi-part-create-reject' + makeid();
    },

    updateMultiPartSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    updateMultiPartScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action.payload} hideHeading={true} toastType="onboard" />, {
        toastId: 'mult-partition-fullfiled-success'
      });
      state.successMsg = 'update' + makeid();
    },
    updateMultiPartScheduleRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action.payload} hideHeading={true} />, {
        toastId: 'mult-partition-fullfiled-error'
      });
    },

    cancleMultiPartSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    cancleMultiPartScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action.payload} hideHeading={true} toastType="onboard"/>, {
        toastId: 'cancel-mult-partition-fullfiled-success'
      });
      state.successMsg = 'cancel' + makeid();
    },
    cancleMultiPartScheduleRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action.payload} hideHeading={true} />, {
        toastId: 'cancel-mult-partition-fullfiled-error'
      });
    },

    createMultiPartCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    createMultiPartCleanupScheduleFulfilled: (state, action) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = true;
    },
    createMultiPartCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
    },

    updateMultiPartCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    updateMultiPartCleanupScheduleFulfilled: (state) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = true;
    },
    updateMultiPartCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
    },

    cancleMultiPartCleanupSchedule: (state, { payload }) => {
      state.inProcess = true;
    },
    cancleMultiPartCleanupScheduleFulfilled: (state) => {
      state.inProcess = false;
      state.cleanupInit.isCleanup = false;
    },
    cancleMultiPartCleanupScheduleRejected: (state, action) => {
      state.inProcess = false;
    }
  }
});

export const {
  resetSchedule,
  setScheduleRequest,
  setMultiPartitionScheduleRequest,
  createScheduleSlice,
  createScheduleFulfilled,
  createScheduleRejected,
  updateSchedule,
  updateScheduleFulfilled,
  updateScheduleRejected,
  cancleSchedule,
  cancleScheduleFulfilled,
  cancleScheduleRejected,
  getScalerSavings,
  getScalerSavingsFulfilled,
  getScalerSavingsRejected,
  setTriggerCleanupApi,
  resetTriggerCleanupApi,
  createCleanupSchedule,
  createCleanupScheduleFulfilled,
  createCleanupScheduleRejected,
  updateCleanupSchedule,
  updateCleanupScheduleFulfilled,
  updateCleanupScheduleRejected,
  cancleCleanupSchedule,
  cancleCleanupScheduleFulfilled,
  cancleCleanupScheduleRejected,
  setCleanupInit,
  resetCleanupInit,
  // multi partition
  createMultiPartScheduleSlice,
  createMultiPartScheduleFulfilled,
  createMultiPartScheduleRejected,
  updateMultiPartSchedule,
  updateMultiPartScheduleFulfilled,
  updateMultiPartScheduleRejected,
  cancleMultiPartSchedule,
  cancleMultiPartScheduleFulfilled,
  cancleMultiPartScheduleRejected,
  createMultiPartCleanupSchedule,
  createMultiPartCleanupScheduleFulfilled,
  createMultiPartCleanupScheduleRejected,
  updateMultiPartCleanupSchedule,
  updateMultiPartCleanupScheduleFulfilled,
  updateMultiPartCleanupScheduleRejected,
  cancleMultiPartCleanupSchedule,
  cancleMultiPartCleanupScheduleFulfilled,
  cancleMultiPartCleanupScheduleRejected
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
