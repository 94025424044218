import { useMsal } from '@azure/msal-react';
import { Box, Grid, Typography } from '@mui/material';
import { setCloudProvider, setPermissions } from 'features/accounts/store/accounts.slice';
import { Permissions } from 'features/accounts/types';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { isUndefineNull } from 'utils/common';
import storage from 'utils/storage';
import ListCloudEntities from '../../shared/components/ListCloudEntities';
import StepOne from '../../shared/components/StepOne';
import StepTwo from '../../shared/components/StepTwo';
import '../assets/style.scss';
import StepThree from './StepThree';
import toast from 'react-hot-toast';
import { stepperData } from '../../utils/helper';
import { Stepper } from '../../shared/components/Stepper';
import TimeLine from '../../shared/components/TimeLine';
import TrackProgress from '../../shared/components/TrackProgress';
import { StyledTooltip } from 'components/StylesTooltip';
import ToolTipIcon from 'assets/images/tootipIcon.svg'

const AzureContainer = () => {
  const { inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const [permission, setPermission] = useState<Permissions>({ AUTO_SCALER: true, AUDIT: true });
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [isTrackProgressEnable, setIsSearchProgressEnable] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setCloudProvider('AZURE'));
    if (storage.getGraphToken() && storage.getManagentToken()) {
      setTokenLoaded(true);
    }
  }, []);

  useEffect(() => {
    dispatch(setPermissions(permission));
  }, [permission]);

  useEffect(() => {
    const gToken = isUndefineNull(storage.getGraphToken());
    const mToken = isUndefineNull(storage.getManagentToken());
    if (!gToken && !mToken) {
      setTokenLoaded(true);
    }
    if (!mToken && gToken) {
      toast.error('Failed to get Graph Token');
    }
    if (mToken && !gToken) {
      toast.error('Failed to get Management Token');
    }
  }, [inProgress]);

  useEffect(() => {
    const trackProgress = searchParams.has('trackProgress')
    setIsSearchProgressEnable(trackProgress)
  },[searchParams])

  return (
    <>
      {(!tokenLoaded && !isTrackProgressEnable) && (
        <>
          <Grid container spacing={2} marginTop={0.5} maxWidth={'1010px'} sx={{ mx: 'auto' }}>
            <Stepper stepsData={stepperData.azureSteps} step={1} />         
            <Grid item xs={10} sx={{ alignItems: 'center' }}>
              <Typography variant="h3" gutterBottom fontSize={36} lineHeight="42px" mb={0.5}>
                Connect your Azure Account
                <StyledTooltip
                  title='Your data on Azure remains secure and is inaccessible to us.'>
                  <img src={ToolTipIcon} style={{ marginLeft: '16px', marginTop: '4px', height: '25px', width: '25px' }} />
                </StyledTooltip>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={0.5} maxWidth={'1100px'} sx={{ mx: 'auto', }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
              <Grid item sx={{ marginTop: 10 }}><TimeLine height={'240px'} /></Grid>
              <Grid item >
                <Grid container  >
                  <StepOne permission={permission} setPermission={setPermission} />
                  <StepTwo />
                </Grid>
                <StepThree />
              </Grid>
            </Box>
          </Grid>   
        </>
      )}
      { (!tokenLoaded && !isTrackProgressEnable) &&
        <Box
        sx={{
          display: 'flex',
          gap: '2px',
          bottom: 20,
          width: '90%',
          position: 'fixed',
          justifyContent: 'center',
          background: '#161718',
          padding: '10px'
        }}>
        <Typography variant="body1" gutterBottom fontSize={16} lineHeight="22px" mb={0}>
          Wan’t to add account manually?
        </Typography>
        <Link to="/accounts/download?code=azure" className="download">
          Download Instructions PDF
        </Link>
      </Box>
      }
      {( isTrackProgressEnable) &&
        <Grid container spacing={2} marginTop={2} maxWidth={'1010px'} sx={{ mx: 'auto' }}>
          <Stepper stepsData={stepperData.azureSteps} step={3} />
          <TrackProgress />
        </Grid>
      }
      {(tokenLoaded && !isTrackProgressEnable) && <ListCloudEntities />}
    </>
  );
};

export default AzureContainer;
