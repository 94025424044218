import Instrumentation from "utils/instrumentation";

class MultipartitionOnboardingInstrumentation extends Instrumentation {
  constructor() {
    super('multipartition_onboarding')
  }

  onboardingInitiated() {
    this.pushEvent({
      onboarding_stage: 'initiated'
    })
  }

  onboardingScheduled({
    partitions }: {
      partitions: number,
  }) {
    this.pushEvent({
      onboarding_stage: 'onboarding_schedule',
      status: 'success',
      partitions,
    })
  }

  onboardingFailure({ partitions, error, onboardingStage }:
    { error: any, partitions: number, onboardingStage: string }) {
    this.pushEvent({
      onboarding_stage: onboardingStage,
      status: 'error',
      status_detail: `${error}`,
      partitions,
    })
  }

  onboardingUpdated({
    partitions
  }: { partitions: number }) {
    this.pushEvent({
      onboarding_stage: 'onboarding_update',
      status: 'success',
      partitions,
    })
  }
}

export default MultipartitionOnboardingInstrumentation;