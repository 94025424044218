import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

interface RootProps {
  focus?: boolean | any;
}

export const Search = styled('div', {
  shouldForwardProp: (prop) => prop !== 'focus'
})<RootProps>(({ theme, focus }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  width: '430px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '12px',
  marginLeft: 'auto',
  border: `1px solid ${focus ? theme.palette.primary.dark : 'rgba(255, 255, 255, 0.10)'}`
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 2),
  height: '20px'
}));

export const CloseIconWrapper = styled('div')(({ theme }) => ({
  height: '20px',
  padding: theme.spacing(0, 1),
  cursor: 'pointer'
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flex: '1 0 auto',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.25, 1.25, 1.25, 0),
    width: '100%',
    fontSize: '14px'
  }
}));

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'focus'
})<RootProps>(({ theme, focus }) => ({
  height: 40,
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  width: 'fit-content',
  borderRadius: '12px',
  border: `1px solid ${focus ? theme.palette.primary.dark : 'rgba(255, 255, 255, 0.10)'}`,
  '&:focus': {
    border: `1px solid ${theme.palette.primary.dark}`
  },
  '& .MuiListItemText-root': {
    padding: 0
  },
  '& .MuiTypography-root': {
    paddingLeft: 10,
    fontSize: 14
  }
}));
