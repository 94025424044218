import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { SimpleToolTip } from 'components/StylesTooltip';
import { FC } from 'react';
import BlurBg from '../../assets/blurBgs.png';
import Lock from '../../assets/lock.svg';
import { anyPartitonAlreadyOnboarded, reachCustomerSupport } from '../../constant';
import { useDiskTypeConversion } from '../../hooks/useDiskTypeConversion';
import InfoBox from '../infoBox';

const DiskTypeCheckBox: FC = () => {
  const {
    checked,
    hoverActive,
    instanceCompatibleInfo,
    diskTypeCoversionFeatureEnable,
    getCheckBoxLabel,
    handleHoverStateOut,
    handleHoverStateIn,
    disableShowToolTipOnCheckBox,
    showToolTipOnCheckBox,
    disableCheckBox,
    handleCheckBoxForDiskTypeConversion,
    anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible
  } = useDiskTypeConversion();

  return (
    <Box sx={{ mb: 1 }} onMouseEnter={handleHoverStateIn} onMouseLeave={handleHoverStateOut} data-testid="diskConversionWrapper">
      {hoverActive && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            height: '72px',
            position: 'relative'
          }}>
          <img src={BlurBg} alt="blur" />
          <Box sx={{ position: 'absolute', display: 'flex', gap: 2, p: 2, alignItems: 'center' }}>
            <img src={Lock} alt="Lock" />
            <Typography variant="body2" fontSize={16}>
              {reachCustomerSupport}
            </Typography>
          </Box>
        </Box>
      )}
      {!hoverActive && (
        <>
          <SimpleToolTip
            showIcon={false}
            text={showToolTipOnCheckBox()}
            disable={disableShowToolTipOnCheckBox()}>
            <FormControlLabel
              data-testid="diskCoversionCheckbox"
              sx={{
                '.MuiButtonBase-root.MuiCheckbox-root': {
                  padding: '4px 8px 0 0'
                },
                '&.MuiFormControlLabel-root': {
                  alignItems: 'flex-start',
                  m: 0
                },
                '.MuiFormControlLabel-label.Mui-disabled': {
                  color: 'white'
                }
              }}
              label={getCheckBoxLabel()}
              control={
                <Checkbox
                  disabled={disableCheckBox()}
                  checked={checked}
                  onChange={handleCheckBoxForDiskTypeConversion}
                />
              }
            />
          </SimpleToolTip>
          {diskTypeCoversionFeatureEnable &&
            instanceCompatibleInfo?.nonCompatible > 0 &&
            instanceCompatibleInfo?.total > 1 && (
              <Typography
                variant="body2"
                fontSize={16}
                mb={1}
                pl={3.8}
                color="rgba(255, 255, 255, 0.50)">
                {`${instanceCompatibleInfo?.nonCompatible} instance isn’t compatible`}
              </Typography>
            )}
        </>
      )}
      {anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible() && (
        <InfoBox content={anyPartitonAlreadyOnboarded} showIcon={false} />
      )}
    </Box>
  );
};

export default DiskTypeCheckBox;
