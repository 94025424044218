import * as slice from '.';
import * as api from '../api';
import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';

import MultipartitionOnboardingInstrumentation from 'features/scalar/utils/instrumentation';

const multipartitionAnalytics = new MultipartitionOnboardingInstrumentation();

export function* onCreateSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.createScheduleApi(payload));
    yield put(slice.createScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
  } catch (e: any) {
    yield put(slice.setTriggerCleanupApi(false));
    yield put(slice.createScheduleRejected(e));
  }
}

export function* onUpdateSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.updateScheduleApi(payload));
    yield put(slice.updateScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
  } catch (e: any) {
    yield put(slice.setTriggerCleanupApi(false));
    yield put(slice.updateScheduleRejected(e));
  }
}

export function* onCancleSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.cancelScheduleApi(payload));
    yield put(slice.cancleScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
  } catch (e: any) {
    yield put(slice.setTriggerCleanupApi(false));
    yield put(slice.cancleScheduleRejected(e));
  }
}

export function* onCreateCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.createScheduleApi(payload));
    yield put(slice.createCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.createCleanupScheduleRejected(e));
  }
}

export function* onUpdateCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.updateScheduleApi(payload));
    yield put(slice.updateCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.updateCleanupScheduleRejected(e));
  }
}

export function* onCancleCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.cancelScheduleApi(payload));
    yield put(slice.cancleCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.cancleCleanupScheduleRejected(e));
  }
}

export function* onGetScalerSavings({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.getScalerSaving(payload));
    yield put(slice.getScalerSavingsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getScalerSavingsRejected(e));
  }
}

/**
 * Multi partition
 */

export function* onCreateMultiPartSchedule({ payload }: any): SagaIterator {
  const numberOfPartitions = payload?.args?.mountPointList?.length;

  try {
    const { data } = yield call(() => api.createMultiPartitionScheduleApi(payload));
    yield put(slice.createMultiPartScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
    multipartitionAnalytics.onboardingScheduled({ partitions: numberOfPartitions })
  } catch (e: any) {
    yield put(slice.createMultiPartScheduleRejected(e));
    yield put(slice.setTriggerCleanupApi(false));
    multipartitionAnalytics.onboardingFailure({ 
      partitions: numberOfPartitions, error: e,
      onboardingStage: 'onboarding_create'
    })
  }
}

export function* onUpdateMultiPartSchedule({ payload }: any): SagaIterator {
  const numberOfPartitions = payload?.args?.mountPointList?.length;

  try {
    const { data } = yield call(() => api.updateMultiPartitionScheduleApi(payload));
    yield put(slice.updateMultiPartScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
    multipartitionAnalytics.onboardingUpdated({ partitions: numberOfPartitions })
  } catch (e: any) {
    yield put(slice.setTriggerCleanupApi(false));
    yield put(slice.updateMultiPartScheduleRejected(e));
    multipartitionAnalytics.onboardingFailure({ 
      partitions: numberOfPartitions, error: e,
      onboardingStage: 'onboarding_update'
    })
  }
}

export function* onCancleMultiPartSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.cancelMultiPartitionScheduleApi(payload));
    yield put(slice.cancleMultiPartScheduleFulfilled(data));
    yield put(slice.setTriggerCleanupApi(true));
  } catch (e: any) {
    yield put(slice.setTriggerCleanupApi(false));
    yield put(slice.cancleMultiPartScheduleRejected(e));
  }
}

export function* onCreateMultiPartCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.createMultiPartitionScheduleApi(payload));
    yield put(slice.createMultiPartCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.createMultiPartCleanupScheduleRejected(e));
  }
}

export function* onUpdateMultiPartCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.updateMultiPartitionScheduleApi(payload));
    yield put(slice.updateMultiPartCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.updateMultiPartCleanupScheduleRejected(e));
  }
}

export function* onCancleMultiPartCleanupSchedule({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.cancelMultiPartitionScheduleApi(payload));
    yield put(slice.cancleMultiPartCleanupScheduleFulfilled(data));
  } catch (e: any) {
    yield put(slice.cancleMultiPartCleanupScheduleRejected(e));
  }
}

// Watcher Saga
export function* scheduleWatcherSaga(): SagaIterator {
  yield takeEvery('schedulerequest/getScalerSavings', onGetScalerSavings);
  yield takeEvery('schedulerequest/createScheduleSlice', onCreateSchedule);
  yield takeEvery('schedulerequest/updateSchedule', onUpdateSchedule);
  yield takeEvery('schedulerequest/cancleSchedule', onCancleSchedule);
  yield takeEvery('schedulerequest/createCleanupSchedule', onCreateCleanupSchedule);
  yield takeEvery('schedulerequest/updateCleanupSchedule', onUpdateCleanupSchedule);
  yield takeEvery('schedulerequest/cancleCleanupSchedule', onCancleCleanupSchedule);
  //multi partition
  yield takeEvery('schedulerequest/createMultiPartScheduleSlice', onCreateMultiPartSchedule);
  yield takeEvery('schedulerequest/updateMultiPartSchedule', onUpdateMultiPartSchedule);
  yield takeEvery('schedulerequest/cancleMultiPartSchedule', onCancleMultiPartSchedule);
  yield takeEvery('schedulerequest/createMultiPartCleanupSchedule', onCreateMultiPartCleanupSchedule);
  yield takeEvery('schedulerequest/updateMultiPartCleanupSchedule', onUpdateMultiPartCleanupSchedule);
  yield takeEvery('schedulerequest/cancleMultiPartCleanupSchedule', onCancleMultiPartCleanupSchedule);
}

export default scheduleWatcherSaga;
