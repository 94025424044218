import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { ScheduleApiRequestPayloadType, MultiPartitionScheduleApiRequestPayloadType, ScalerSavingsResponse } from '../../shared/types';

export const createScheduleApi = (
  payload: ScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/job-schedule/${payload?.scheduleType}/create`, payload.args);

export const updateScheduleApi = (
  payload: ScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/job-schedule/${payload?.scheduleType}/update`, payload.args);

export const cancelScheduleApi = (
  payload: ScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/job-schedule/${payload?.scheduleType}/cancel`, payload.args);

export const createMultiPartitionScheduleApi = (
  payload: MultiPartitionScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/bulk/job-schedule/${payload?.scheduleType}/create`, payload.args);

export const updateMultiPartitionScheduleApi = (
  payload: MultiPartitionScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/bulk/job-schedule/${payload?.scheduleType}/update`, payload.args);

export const cancelMultiPartitionScheduleApi = (
  payload: MultiPartitionScheduleApiRequestPayloadType
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/bulk/job-schedule/${payload?.scheduleType}/cancel`, payload.args);

export const getScalerSaving = (filter: any): Promise<ApiResponse<ScalerSavingsResponse>> =>
  ApiService.put<ScalerSavingsResponse>(`/api/v1/scaler-savings`, filter);
