import { Box } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { FC, useEffect, useState } from 'react';
// local import
import GlobalSpinner from 'components/GlobalSpinner';
import AlertComponenet from 'components/common/AlertComponents';
import { useSearchParams } from 'react-router-dom';
import { toast as toasts } from 'react-toastify';
import CompanyLogo from '../assets/images/logo.svg';
import ServerMan from '../assets/images/server_man.svg';
import '../assets/styles.scss';
import OktaSignInWidget from './OktaSigninWidget';
import storage from 'utils/storage';
import oktaSamlInstrumentation from '../utils/instrumentation/okta'

const SigninContainer: FC = () => {
  const oktaAnalytics = oktaSamlInstrumentation.initialize();

  const { oktaAuth } = useOktaAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    storage.deleteMpToken();
    let timeId: NodeJS.Timeout;
    const isSaml = searchParams.get('sso');
    setLoading(true);
    if (isSaml === 'true') {
      oktaAnalytics.dispatchSamlRequest();

      oktaAuth.session
        .exists()
        .then((exists) => {
          if (exists) {
            oktaAnalytics.samlSuccess({
              email: oktaAuth?.authStateManager?.getAuthState()?.accessToken?.claims?.sub
            })
            oktaAuth.token
              .getWithRedirect()
              .finally(() => ( timeId = setTimeout(() => setLoading(false), 1200)));
          } else {
            oktaAnalytics.authError({ error: { message: 'Failed to get a Session' }, isSaml: true })
            setLoading(false);
            toasts(<AlertComponenet msg={'Failed to get session'} hideHeading />, {
              toastId: 'sso-login'
            });
          }
        })
        .catch((error: any) => {
          oktaAnalytics.authError({ error, isSaml: true })
          setLoading(false);
          toasts(<AlertComponenet msg={'Failed to get session'} hideHeading />, {
            toastId: 'sso-login'
          });
        });
    } else {
      setLoading(false);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (loading) return <GlobalSpinner />;

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh'
      }}>
      <Box sx={{ p: 6, flexBasis: '50%', background: 'rgba(255, 255, 255, 0.05)' }}>
        <Box sx={{ width: 180 }}>
          <img src={CompanyLogo} alt="lucidity" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
          <img src={ServerMan} alt="ServerMan" />
        </Box>
      </Box>
      <Box
        sx={{
          pt: 5,
          pb: 5,
          flexBasis: '50%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          flexDirection: 'column'
        }}>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden'
          }}>
          <OktaSignInWidget />
        </Box>
      </Box>
    </Box>
  );
};

export default SigninContainer;
