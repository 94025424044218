import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  UnManagedVmsResponse,
  MultiplePartitionOnboardResponse,
  MultiplePartitionOnboardRequest,
  MirrorInProgressMetricsType,
  MirrorInProgressRequestType
} from '../types';

export const getUnManagedVmsApi = (payload: any): Promise<ApiResponse<UnManagedVmsResponse>> =>
  ApiService.put<UnManagedVmsResponse>(`/api/v2/virtual/unmanaged-vm`, payload.filter,
    {
      params: { 
        ...(payload.sort ? { sort: payload.sort } : {}),
        ...(payload.rebootQuery ? { filter: payload.rebootQuery } : {}) 

      }
    }
  );

export const getMultiplePartitionOnboardApi = (
  payload: MultiplePartitionOnboardRequest
): Promise<ApiResponse<MultiplePartitionOnboardResponse>> =>
  ApiService.put<MultiplePartitionOnboardResponse>(
    `/api/v3/virtual/unmanaged-vm/multiple-partition-onboard`,
    payload
  );

export const searchUnManagedVmsApi = (payload: any): Promise<ApiResponse<UnManagedVmsResponse>> =>
  ApiService.put<UnManagedVmsResponse>(
    `/api/v1/search/unmanaged?query=${payload.searchQuery}`,
    payload.filter,
    {
      params: { ...(payload.sort ? { sort: payload.sort } : {}),
                ...(payload.rebootQuery ? { filter: payload.rebootQuery } : {}) 
              }
    }
  );


export const getMirrorInProgressMetricsApis = (payload: MirrorInProgressRequestType): Promise<ApiResponse<MirrorInProgressMetricsType>> => 
  ApiService.get(`/api/v1/onboard/progress?tenantId=${payload.tenantId}&instanceId=${payload?.instanceId}&mountPoint=${payload.mountPoint}`);

