import { createSlice } from '@reduxjs/toolkit';
import { KubManagedSliceType } from '../types';

const initialState: KubManagedSliceType = {
  managedCluster: {
    managedClusterData: null,
    status: 'ready'
  },
  describeCluster: {
    describeClusterData: null,
    status: 'ready'
  },
  listPv: {
    listPvData: null,
    status: 'ready'
  },
  opt: {
    optData: null,
    status: 'ready'
  },
  pvMetrics: {
    pvMetricsData: null,
    status: 'ready'
  },
  moreDetailModalProps: null
};

const kubManagedSlice = createSlice({
  name: 'managed',
  initialState,
  reducers: {
    setMoreDetailModalProps: (state, action) => {
      state.moreDetailModalProps = { ...state.moreDetailModalProps, ...action.payload };
    },

    getManagedClusterInit: (state, { payload }) => {
      state.managedCluster = { status: 'loading', managedClusterData: null };
    },
    getManagedClusterSuccess: (state, action) => {
      state.managedCluster = {
        managedClusterData: action.payload,
        status: 'success'
      };
    },
    getManagedClusterError: (state) => {
      state.managedCluster = { status: 'error', managedClusterData: null };
    },

    getDescribeClusterInit: (state, { payload }) => {
      state.describeCluster = { status: 'loading', describeClusterData: null };
    },
    getDescribeClusterSuccess: (state, action) => {
      state.describeCluster = { describeClusterData: action.payload, status: 'success' };
    },
    getDescribeClusterError: (state, action) => {
      state.describeCluster = { status: 'error', describeClusterData: null };
    },

    getManagedListPvInit: (state, { payload }) => {
      state.listPv = { status: 'loading', listPvData: null };
    },
    getManagedListPvSuccess: (state, action) => {
      state.listPv = { listPvData: action.payload, status: 'success' };
    },
    getManagedListPvrError: (state, action) => {
      state.listPv = { status: 'error', listPvData: null };
    },

    getPvOPTInit: (state, { payload }) => {
      state.opt = { optData: null, status: 'loading' };
    },
    getPvOPTSuccess: (state, action) => {
      state.opt = { optData: action.payload, status: 'success' };
    },
    getPvOPTError: (state, action) => {
      state.opt = { optData: null, status: 'error' };
    },

    getPvMetricsInit: (state, { payload }) => {
      state.pvMetrics = { pvMetricsData: null, status: 'loading' };
    },
    getPvMetricsSuccess: (state, action) => {
      state.pvMetrics = { pvMetricsData: action.payload, status: 'success' };
    },
    getPvMetricsError: (state, action) => {
      state.pvMetrics = { pvMetricsData: null, status: 'error' };
    }
  }
});

export const {
  setMoreDetailModalProps,
  getPvMetricsInit,
  getPvMetricsSuccess,
  getPvMetricsError,
  getPvOPTInit,
  getPvOPTSuccess,
  getPvOPTError,
  getDescribeClusterInit,
  getDescribeClusterSuccess,
  getDescribeClusterError,
  getManagedListPvInit,
  getManagedListPvSuccess,
  getManagedListPvrError,
  getManagedClusterInit,
  getManagedClusterSuccess,
  getManagedClusterError
} = kubManagedSlice.actions;

export default kubManagedSlice.reducer;
