import React, { FC, useEffect } from 'react';
import CustomizedDialogs from 'components/modalContainer';
import PlannedOnbardingContent from './plannedOnbardingContent';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setModalPreference,
  updatebulkScheduleInit,
  cancelbulkScheduleInit,
  setScheduleModalProps,
  resetBulkOnboardSchedulePayload,
  resetPlannedOnboarding
} from '../../store';
import ScheduleOnboardModal from '../modal/ScheduleOnboardModal';
import ConfirmCancelOnboarding from '../modal/ConfirmCancelOnboarding';

const PlannedOnboarding: FC = () => {
  const dispatch = useAppDispatch();
  const { modalPreference, bulkOnboardSchedulePayload, bulkSchedule } = useAppSelector(
    (state) => state.unmanagedVm
  );

  const resetState = () => {
    dispatch(setScheduleModalProps(null));
    dispatch(resetBulkOnboardSchedulePayload());
    dispatch(resetPlannedOnboarding());
  };

  useEffect(() => {
    if (bulkSchedule.status !== 'ready') resetState();
  }, []);

  const handleUpdateOnboard = () => {
    dispatch(updatebulkScheduleInit(bulkOnboardSchedulePayload));
  };

  const handleCancelOnboard = () => {
    dispatch(cancelbulkScheduleInit(bulkOnboardSchedulePayload));
  };

  const handleModalClose = () => {
    dispatch(setModalPreference(null));
    resetState();
  };

  return (
    <CustomizedDialogs
      handleClose={handleModalClose}
      open={
        modalPreference === 'plannedOnboarding' ||
        modalPreference === 'onboardUpdate' ||
        modalPreference === 'onboardCancel'
      }
      style={{
        '.MuiDialog-container': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        '& .MuiPaper-elevation': {
          background: 'rgba(255, 255, 255, 0.05)',
          border: '1px solid #454546'
        },
        '& .MuiDialogContent-root':
          modalPreference === 'plannedOnboarding'
            ? {
              width: 1200,
              minHeight: 500,
              maxHeight: 650,
              overflow: 'hidden',
              padding: '24px 0 0 0'
            }
            : {}
      }}>
      {modalPreference === 'plannedOnboarding' && <PlannedOnbardingContent />}
      {modalPreference === 'onboardUpdate' && (
        <ScheduleOnboardModal
          handleModalClose={handleModalClose}
          handleOnboard={handleUpdateOnboard}
        />
      )}
      {modalPreference === 'onboardCancel' && (
        <ConfirmCancelOnboarding
          handleModalClose={handleModalClose}
          handleCancelOnboard={handleCancelOnboard}
        />
      )}
    </CustomizedDialogs>
  );
};

export default PlannedOnboarding;
