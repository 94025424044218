import { Paper, Table, TableContainer } from '@mui/material';
import GlobalSpinner from 'components/GlobalSpinner';
import CustomizedDialogs from 'components/modalContainer';
import NovmsMessage from 'features/scalar/components/shared/components/NovmsMessage';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  bulkScheduleInit,
  resetBulkOnboardSchedulePayload,
  resetPlannedOnboarding,
  setBulkOnboardSchedulePayload,
  setModalPreference,
  setScheduleModalProps
} from '../../store';
import { BulkOnboardInstancesInfo, UnManagedVmsRes } from '../../types';
import ScheduleOnboardModal from '../modal/ScheduleOnboardModal';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import PreOnboardingWin2012Message from '../modal/preOnboardingWin2012Message';
import OnboardingProgress from '../onboardingProgress';



const InstanceToOnboardTable = () => {
  const dispatch = useAppDispatch();
  const { unmanagedVmsData, status, bulkSchedule, modalPreference, bulkOnboardSchedulePayload } =
    useAppSelector((state) => state.unmanagedVm);
  const [onboardingGroup, setOnboardingGroup] = useState<string>('');
  const [selectedPartition, setSelectedPartition] = useState<BulkOnboardInstancesInfo[]>([]);

  const resetState = () => {
    dispatch(setScheduleModalProps(null));
    dispatch(resetBulkOnboardSchedulePayload());
    setSelectedPartition([]);
    setOnboardingGroup('');
  };

  const handleModalClose = () => {
    dispatch(setModalPreference(null));
    resetState();
  };

  useEffect(() => {
    dispatch(setBulkOnboardSchedulePayload({ mountPointInfo: selectedPartition }));
  }, [selectedPartition]);

  useEffect(() => {
    if (
      bulkSchedule?.status === 'success' ||
      bulkSchedule?.status === 'error' ||
      status === 'loading'
    ) {
      setSelectedPartition([]);
      setOnboardingGroup('');
    }
  }, [bulkSchedule, status]);

  useEffect(() => {
    if (modalPreference === 'plannedOnboarding') {
      resetState();
    } 
    if (modalPreference !== 'plannedOnboarding' && modalPreference !== 'onboardUpdate') {
      dispatch(resetPlannedOnboarding())
    }
  }, [modalPreference]);

  const handleOnboard = () => {
    if (bulkOnboardSchedulePayload && bulkOnboardSchedulePayload?.mountPointInfo?.length > 0)
      dispatch(bulkScheduleInit(bulkOnboardSchedulePayload));
  };

  const getStyle = () => {
    if (modalPreference === 'mirrorMigration') {
      return {
        width: 800,
        height: 528,
      }
    } else {
      return {
        width: 600
      }
    }
  }

  if (status === 'loading') return <GlobalSpinner height="50vh" />;
  if (!unmanagedVmsData?.data || unmanagedVmsData?.data?.length < 1) return <NovmsMessage />;

  return (
    <>
      <CustomizedDialogs
        handleClose={handleModalClose}
        open={modalPreference === 'onboard' || modalPreference === 'win2012disclaimer' || modalPreference === 'mirrorMigration'}
        style={{
          '& .MuiDialogContent-root': {
            ...getStyle()
          },
          '.MuiDialog-container': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          '& .MuiPaper-elevation': {
            background: 'rgba(255, 255, 255, 0.05)',
            border: '1px solid #454546'
          }
        }}>
        {modalPreference === 'win2012disclaimer' && <PreOnboardingWin2012Message />}
        {modalPreference === 'onboard' && (
          <ScheduleOnboardModal handleOnboard={handleOnboard} handleModalClose={handleModalClose} />
        )}
        {
          modalPreference === 'mirrorMigration' &&
          <OnboardingProgress />
        }
      </CustomizedDialogs>

      <TableContainer
        component={Paper}
        sx={{
          border: '1px solid #454546',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '16px'
        }}>
        <Table aria-label="collapsible table" sx={{ backgroundColor: 'rgba(31, 32, 35, 0.28)' }}>
          <TableHeader />
          {unmanagedVmsData?.data?.map((instance: UnManagedVmsRes, instanceIndex: number) => (
            <TableRow
              key={instance?.instanceId}
              instance={instance}
              instanceIndex={instanceIndex}
              onboardingGroup={onboardingGroup}
              setOnboardingGroup={setOnboardingGroup}
              selectedPartition={selectedPartition}
              setSelectedPartition={setSelectedPartition}
            />
          ))}
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(InstanceToOnboardTable);
