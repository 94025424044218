import Instrumentation from 'utils/instrumentation';

interface OnboardingScheduledProps {
  noOfPartitions: number;
  noOfInstance: number;
  onboardingStage: string;
}

interface OnboardingFailureProps extends OnboardingScheduledProps {
  error: any;
}

interface DiskConversion {
  conversionCheckBoxChecked: boolean
  noOfInstance: number
  noOfPartitions: number
  compatibilityStatus: 'all' | 'partial'
}

class UnmanagedVmInstrumentation extends Instrumentation {
  constructor() {
    super('bulk_onboarding');
  }

  onboardingInitiated() {
    this.pushEvent({
      onboarding_stage: 'initiated'
    });
  }

  onboardingScheduled(args: OnboardingScheduledProps) {
    this.pushEvent({
      onboarding_stage: args?.onboardingStage,
      status: 'success',
      no_of_partitions: args.noOfPartitions,
      no_of_instance: args.noOfInstance
    });
  }

  onboardingFailure(args: OnboardingFailureProps) {
    this.pushEvent({
      onboarding_stage: args?.onboardingStage,
      status: 'error',
      status_detail: `${args.error}`,
      no_of_partitions: args.noOfPartitions,
      no_of_instance: args.noOfInstance
    });
  }

  diskConversion(args: DiskConversion) {
    this.pushEvent({
      conversion_checkBox_checked: args.conversionCheckBoxChecked,
      no_of_instance: args.noOfInstance,
      no_of_partitions: args.noOfPartitions,
      compatibility_status: args.compatibilityStatus,
    });
  }
}

export default UnmanagedVmInstrumentation;
