import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createUserApi,
  getRoleListApi,
  getSingleUserDetailApi,
  getUserListApi,
  updateUserRoleApi,
  resetPasswordApi
} from '../api';
import * as userSlice from './users.slice';

export function* onGetUsersList(): SagaIterator {
  try {
    const { data } = yield call(() => getUserListApi());
    yield put(userSlice.getUsersListFulfilled(data));
  } catch (e: any) {
    yield put(userSlice.getUsersListRejected(e));
  }
}
export function* onCreateUser({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => createUserApi(payload));
    yield put(userSlice.createUserFulfilled(data));
    yield put(userSlice.isInviteSent(true));
  } catch (e: any) {
    yield put(userSlice.createUserRejected(e));
    yield put(userSlice.isInviteSent(false));
  }
}

export function* onUpdateUsersRole({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => updateUserRoleApi(payload));
    yield put(userSlice.updateUsersRoleFulfilled(data));
  } catch (e: any) {
    yield put(userSlice.updateUsersRolerRejected(e));
  }
}
export function* onDeleteDeactiveUser({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => updateUserRoleApi(payload));
    yield put(userSlice.updateUsersRoleFulfilled(data));
    try {
      const rdata = yield call(() => getUserListApi());
      yield put(userSlice.getUsersListFulfilled(rdata.data));
    } catch (e: any) {
      yield put(userSlice.getUsersListRejected(e));
    }
  } catch (e: any) {
    yield put(userSlice.updateUsersRolerRejected(e));
  }
}
export function* onGetRoleList(): SagaIterator {
  try {
    const { data } = yield call(() => getRoleListApi());
    yield put(userSlice.getRoleListFulfilled(data));
  } catch (e: any) {
    yield put(userSlice.getRoleListRejected(e));
  }
}
export function* onGetSingleUserDetail({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getSingleUserDetailApi(payload));
    yield put(userSlice.getSingleUsersDetailFulfilled(data));
  } catch (e: any) {
    yield put(userSlice.getSingleUsersDetailRejected(e));
  }
}
export function* onResetPassword({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => resetPasswordApi(payload));
    yield put(userSlice.resetPasswordFulfilled(data));
  } catch (e: any) {
    yield put(userSlice.resetPasswordRejected(e));
  }
}

// Watcher Saga
export function* usersWatcherSaga(): SagaIterator {
  yield takeEvery('users/getUsersList', onGetUsersList);
  yield takeEvery('users/createUserInit', onCreateUser);
  yield takeEvery('users/updateUsersRole', onUpdateUsersRole);
  yield takeEvery('users/deleteDeactivUser', onDeleteDeactiveUser);
  yield takeEvery('users/getRoleList', onGetRoleList);
  yield takeEvery('users/getSingleUsersDetail', onGetSingleUserDetail);
  yield takeEvery('users/resetPassword', onResetPassword);
}

export default usersWatcherSaga;
