import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { MirrorMetricsSlice } from '../../types';

const GraphInfo: FC<MirrorMetricsSlice> = (props) => {
  const { data } = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0 4px'
      }}>
      <Content title="Onboarding started" date={data?.startedAt ? format(new Date(data?.startedAt), 'MMM d, Y • HH:mm') : '_'} />
      <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2.5, my: 0, borderWidth: 1 }} />
      <Content title="Time Lapsed" date={data?.timeLapsed} />
      <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2.5, my: 0, borderWidth: 1 }} />
      <Content title="Time Remaining" date={data?.timeRemaining} />
      <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2.5, my: 0, borderWidth: 1 }} />
      <Content title="Scheduled Reboot" date={data?.scheduledRebootTime ? format(new Date(data?.scheduledRebootTime), 'MMM d, Y • HH:mm') : '_'} />
    </Box>
  );
};

export default GraphInfo;

const Content: FC<any> = ({ title, date }) => {
  return (
    <Box sx={{ flexBasis: '25%' }}>
      <Typography variant="body1" fontSize={14} color="rgba(255, 255, 255, 0.5)">
        {title}
      </Typography>
      <Typography variant="body1" fontSize={14} fontWeight="500">
        {date}
      </Typography>
    </Box>
  );
};
