import { createSlice } from '@reduxjs/toolkit';
// local import
import { toast } from 'react-hot-toast';
import { UsersSliceType } from '../types';
import { toastErrorMsg } from 'utils/common';
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';

const initialState: UsersSliceType = {
  usersList: [],
  inProcess: undefined,
  inProcessResetPwd: undefined,
  createUserError: undefined,
  roleList: undefined,
  inviteSent: undefined
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersList: (state) => {
      state.inProcess = true;
    },
    getUsersListFulfilled: (state, action) => {
      state.inProcess = false;
      state.usersList = action?.payload?.users;
    },
    getUsersListRejected: (state, action) => {
      state.inProcess = false;
      state.usersList = []
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'user-list-error'});
    },

    isInviteSent: (state, action) => {
      state.inviteSent = action.payload
    },
    resetInviteSent: (state) => {
      state.inviteSent = undefined
    },

    createUserInit: (state, { payload }) => {
      state.inProcess = true;
    },
    createUserFulfilled: (state) => {
      state.inProcess = false;
    },
    createUserRejected: (state, action) => {
      state.inProcess = false;
      state.createUserError = action.payload;
      toastErrorMsg(action.payload,'create-user-error');
    },

    getRoleList: (state) => {
      state.inProcess = true;
    },
    getRoleListFulfilled: (state, action) => {
      state.inProcess = false;
      state.roleList = action.payload;
    },
    getRoleListRejected: (state, action) => {
      state.inProcess = false;
      state.createUserError = action.payload;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'getRoleList-error'});
    },

    deleteDeactivUser: (state, { payload }) => {
      state.inProcess = true;
    },
    updateUsersRole: (state, { payload }) => {
      state.inProcess = true;
    },
    updateUsersRoleFulfilled: (state, action) => {
      state.inProcess = false;
      state.roleList = action.payload;
      toast.success('Updated successfully', { id: 'update-role-success' });
    },
    updateUsersRolerRejected: (state, action) => {
      state.inProcess = false;
      toastErrorMsg(action.payload,'create-update-role-error');
    },

    getSingleUsersDetail: (state, { payload }) => {
      state.inProcess = true;
    },
    getSingleUsersDetailFulfilled: (state, action) => {
      state.inProcess = false;
      state.roleList = action.payload;
    },
    getSingleUsersDetailRejected: (state, action) => {
      state.inProcess = false;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'getSingleUsersDetail-error'});
    },

    resetPassword: (state, { payload }) => {
      state.inProcessResetPwd = true;
    },
    resetPasswordFulfilled: (state, action) => {
      state.inProcessResetPwd = false;
      toasts(<AlertComponenet msg={action?.payload?.message} hideHeading/>, {toastId: 'resetPassword-success'});
    },
    resetPasswordRejected: (state, action) => {
      state.inProcessResetPwd = false;
      toasts(<AlertComponenet msg={action?.payload?.message}/>, {toastId: 'resetPassword-error'});
    }
  }
});

export const {
  getRoleList,
  getRoleListFulfilled,
  getRoleListRejected,
  getUsersList,
  getUsersListFulfilled,
  getUsersListRejected,
  createUserInit,
  createUserFulfilled,
  createUserRejected,
  updateUsersRole,
  deleteDeactivUser,
  updateUsersRoleFulfilled,
  updateUsersRolerRejected,
  getSingleUsersDetail,
  getSingleUsersDetailRejected,
  getSingleUsersDetailFulfilled,
  isInviteSent,
  resetInviteSent,
  resetPassword,
  resetPasswordFulfilled,
  resetPasswordRejected
} = usersSlice.actions;

export default usersSlice.reducer;
