import { CloudProviderCaps } from 'types/common';
import { PermissionUpdateRequire, TenantInfoV3KeyType, TenantV3ResponseType } from '../types';

export const getTenantPermission = (tenantData: TenantV3ResponseType) => {
  const temp: PermissionUpdateRequire = {'AWS': false, 'AZURE': false, 'GCP': false};
  Object.keys(tenantData.tenantInfoList)?.forEach((cloud: string) => {
    const tenant = tenantData.tenantInfoList[cloud as TenantInfoV3KeyType];
    for (let i = 0; i < tenant?.length; i++) {
      if (tenant[i]?.permissionUpdateNeededOnDashboardName) {
        temp[cloud] = true;
        break;
      }
    }
  });
  return temp;
};
