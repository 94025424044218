import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { tenantsApi } from '../api';
// loca;
import { getTenantFulfilled, getTenantRejected } from '../store';

export function* onGetTenants(): SagaIterator {
  try {
    const { data } = yield call(() => tenantsApi());
    yield put(getTenantFulfilled(data));
  } catch (e: any) {
    yield put(getTenantRejected(String(e)));
  }
}

// Watcher Saga
export function* tenantWatcherSaga(): SagaIterator {
  yield takeEvery('tenants/getTenantPending', onGetTenants);
}

export default tenantWatcherSaga;
