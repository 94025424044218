import { createSlice } from '@reduxjs/toolkit';
// local import
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
import storage from 'utils/storage';
import { TenantSliceType } from '../types';
import { getTenantPermission } from '../utils';

export const initialState: TenantSliceType = {
  tenantData: undefined,
  inProcess: undefined,
  permissionUpdateRequire: undefined,
  error: null
};

const tenantSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    savedTenants: (state, action) => {
      state.tenantData = action.payload;
    },
    getTenantPending: (state) => {
      state.inProcess = true;
    },
    getTenantFulfilled: (state, action) => {
      state.inProcess = false;
      state.permissionUpdateRequire = getTenantPermission(action.payload)
      state.tenantData = action.payload;
      tenantSlice.caseReducers.savedTenants(state, action);
      storage.setIsNewUser(action.payload)
      storage.setXAccount(action.payload.accountId)
    },
    getTenantRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} hideHeading={true}/>, {toastId: 'getTenant-error'});
      state.inProcess = false;
      state.error = action.payload ?? null;
    },
    logout: () => {
      // 
    }
  }
});

export const { savedTenants, getTenantPending, getTenantFulfilled, getTenantRejected,logout } =
  tenantSlice.actions;

export default tenantSlice.reducer;
