import { FC } from 'react';
import { Grid, Box } from '@mui/material';

type PropTypes = {
  height?: string;
};

const TimeLine: FC<PropTypes> = ({ height = '352px' }) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Box
        sx={{
          position: 'relative',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          border: '2px solid #888',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: '#888'
          }}
        />
      </Box>
      <Box
        sx={{
          borderLeft: '2px dashed #454546',
          height: height
        }}
      />
      <Box
        sx={{
          position: 'relative',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          backgroundColor: 'transparent',
          border: '2px solid #888',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: '#888'
          }}
        />
      </Box>
    </Grid>
  );
};
export default TimeLine;
