import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  DescribeClusterRes,
  ManagedClustersReq,
  ManagedClustersRes,
  ManagedListPvReq,
  ManagedListPvRes,
  OperationTimelineReq,
  OperationTimelineRes,
  PvMetricsRes
} from '../types';

export const getManagedClustersApi = (
  payload: ManagedClustersReq
): Promise<ApiResponse<ManagedClustersRes[]>> => 
  ApiService.put(`/api/v1/managed/listClusters`, payload);

export const getDescribeClusterApi = (
  payload: ManagedListPvReq
): Promise<ApiResponse<DescribeClusterRes>> => ApiService.put(`/api/v1/describeCluster`, payload);

export const getManagedListPvsApi = (
  payload: ManagedListPvReq
): Promise<ApiResponse<ManagedListPvRes[]>> => ApiService.put(`/api/v1/managed/listPvs`, payload);

export const getPvOperationTimelineApi = (
  payload: OperationTimelineReq
): Promise<ApiResponse<OperationTimelineRes>> =>
  ApiService.put(`/api/v1/pv/operationTimeline`, payload);

export const getPvMetricsApi = (payload: any): Promise<ApiResponse<PvMetricsRes>> =>
  ApiService.get(`/api/v1/pv/metrics`, {
    params: {
      tenantId: payload.tenantId,
      diskpoolId: payload.diskPoolId,
      ...(payload.duration ? { duration: payload.duration } : {}),
      ...(payload.startTime ? { startTime: payload.startTime } : {}),
      ...(payload.endTime ? { endTime: payload.endTime } : {})
    }
  });
