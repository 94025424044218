import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  src: string;
  width?: number;
  height?: number;
}

const SvgIcons: FC<Props> = ({ src, width = 20, height = 20 }): JSX.Element => {
  return (
    <Box>
      <img src={src} alt="icons" height={height} width={width} />
    </Box>
  );
};

export default SvgIcons;
