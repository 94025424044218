import { FC } from 'react';
import { AppBar, Toolbar, Typography, Divider, Tooltip, Box } from '@mui/material';
import { useMsal } from '@azure/msal-react';
// image
import Bell from '../../assets/images/layout/bell.svg';
import Logout from '../../assets/images/layout/logout.svg';
// local import
import { DRAWERWIDTH } from 'constants/pages';
import storage from 'utils/storage';

import { logout } from 'features/auth/store';
import { useAppDispatch } from 'store/hooks';
import AccountList from './accountList';

const drawerWidth = DRAWERWIDTH;

const TopNav: FC<any> = ({tenantData}) => {
  const dispatch = useAppDispatch()

  const { instance } = useMsal();
  const handleLogout = async () => {
    dispatch(logout())
    if (storage.getAuthType() === 'azure') {
      const logoutRequest = {
        mainWindowRedirectUri: "/signin",
        postLogoutRedirectUri: "/signin",
      };
      storage.clearCookies();
      localStorage.clear();
      instance.logoutPopup(logoutRequest);
    } else {
      await storage.clearAllStorage();
    }
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` }
      }}
      className="appbar">
      <Toolbar className="toolbar">
        <Typography variant="h6" noWrap component="div" sx={{
          textTransform: 'capitalize',
        }}>
          {tenantData?.accountName}
        </Typography>
        <AccountList />
        <Typography
          variant='h6'
          noWrap
          href="https://sites.google.com/lucidity.cloud/lucidity-knowledgebase/getting-started"
          target='_blank'
          component='a'
          sx={{ marginLeft: 'auto', fontSize: '16px' }}
        >
          Documentation
        </Typography>
        <Box className="tool_icon">
          {/* <img src={Bell} alt="bell" /> */}
          <Box className="icon_border" />
          <Tooltip title="Logout">
            <img src={Logout} alt="logout" onClick={handleLogout} />
          </Tooltip>
        </Box>
      </Toolbar>
      <Divider sx={{ borderColor: 'rgba(225, 225, 225, 0.06)' }} />
    </AppBar>
  );
};

export default TopNav;
