import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { PlannedScheduleTriggerResponse } from '../../../types';

interface TitleType {
  selectedPlannedSchedule: PlannedScheduleTriggerResponse | undefined
}
const Title:FC<TitleType> = ({selectedPlannedSchedule}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
      <Typography variant="h4" fontSize={16} lineHeight="24px">
        {selectedPlannedSchedule?.totalInstanceCount} instances
      </Typography>
      <Typography variant="h4" fontSize={16} lineHeight="24px" color="gray">
        Volume status: 0 onboarded &bull; {selectedPlannedSchedule?.inProgressVolumeCount} in
        progress &bull; {selectedPlannedSchedule?.scheduledVolumeCount} scheduled
      </Typography>
    </Box>
  );
};

export default Title; 
