import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  UnManagedVmsRes,
  UnManagedVmReqPayload,
  MirrorInProgressRequestType,
  MirrorInProgressMetricsType,
  BulkOnboardSchedulePayload
} from '../types';

export const getUnManagedVmApi = (payload: UnManagedVmReqPayload): Promise<ApiResponse<UnManagedVmsRes[]>> =>
  ApiService.put(`/api/v3/virtual/unmanaged-vm`, payload.filter, {
    params: { 
      ...(payload.sortUnvmQuery ? { sort: payload.sortUnvmQuery } : {}),
      ...(payload.rebootQuery ? { filter: payload.rebootQuery } : {}),
      ...(payload.query ? { query: payload.query } : {}),
      ...(payload.reboortNonRebootFilter ? { filter: payload.reboortNonRebootFilter } : {})
    }
  });

export const getMirrorInProgressMetricsApis = (
  payload: MirrorInProgressRequestType
): Promise<ApiResponse<MirrorInProgressMetricsType>> =>
  ApiService.get(
    `/api/v1/onboard/progress?tenantId=${payload.tenantId}&instanceId=${payload?.instanceId}&mountPoint=${payload.mountPoint}`
  );

export const createBulkScheduleApi = (
  payload: BulkOnboardSchedulePayload
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v2/job-schedule/ONBOARD/create`, payload);

export const updateBulkScheduleApi = (
  payload: BulkOnboardSchedulePayload
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v2/job-schedule/ONBOARD/update`, payload);
  
export const cancelBulkScheduleApi = (
  payload: BulkOnboardSchedulePayload
): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v2/job-schedule/ONBOARD/cancel`, payload);

export const getPlannedOnboardingApis = (): Promise<ApiResponse<any>> =>
  ApiService.get(`/api/v2/bulk/ONBOARD/schedules`);
