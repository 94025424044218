import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { TenantResponseType, TenantV3ResponseType, LayoutControlRes } from '../types';

export const tenantsApiold = (): Promise<ApiResponse<TenantResponseType>> =>
  ApiService.get<TenantResponseType>(`/api/v2/accountId/tenants`);

export const tenantsApi = (): Promise<ApiResponse<TenantV3ResponseType>> =>
  ApiService.get<TenantV3ResponseType>(`/api/v1/layout/homepage`);

export const layoutControlApi = (): Promise<ApiResponse<LayoutControlRes>> =>
  ApiService.get<LayoutControlRes>(`/api/v1/layout/homepage`);
