import { KubernetesSavingsResponse,KubernatesSavingsPayload, SimplifyPvCreationResponse } from '../types/index';
import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';

export const getKubernetesSaving = (
  payload: KubernatesSavingsPayload
): Promise<ApiResponse<KubernetesSavingsResponse>> =>
  ApiService.put<KubernetesSavingsResponse>(`/api/v1/k8s-savings`, payload);

export const getSimplifyPvCreation = (): Promise<ApiResponse<SimplifyPvCreationResponse>> =>
  ApiService.get<SimplifyPvCreationResponse>(`/api/v1/lucidityStorageClassYaml`);
