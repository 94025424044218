import { Configuration, PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";
import { ORIGIN } from "utils/common";
import config from 'config';
import { AzureLoginRequestScopeType } from 'types/signin.model';

export const msalConfig: Configuration = {
  auth: {
    clientId: `${config.azureClientId}`,
    authority: `${config.azureAuthority}`,
    redirectUri: `${ORIGIN}${config.azureRedirectUri}`
  },
  cache: {
    cacheLocation: BrowserCacheLocation.MemoryStorage,
    storeAuthStateInCookie: true
  }
};

export const loginRequest: AzureLoginRequestScopeType = {
  scopes: [`${config.azureScope}`,]
};

export const msalConfiguration = new PublicClientApplication(msalConfig);

export const msalConfigAccount: Configuration = {
  auth: {
    clientId: config.azureClientIdAccounts,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: `${ORIGIN}${config.azureRedirectUriAccounts}`,    
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false 
  }
};
export const accountsGrpahScope = {
  scopes: ['https://graph.microsoft.com/Application.ReadWrite.All'],
  prompt: 'select_account'
}
export const accountsManagementScope = {
  scopes: ['https://management.core.windows.net/user_impersonation'],
  prompt: 'select_account'
}

export const msalConfigurationAccount = new PublicClientApplication(msalConfigAccount);
