import { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  cancelBulkScheduleApi,
  createBulkScheduleApi,
  getMirrorInProgressMetricsApis,
  getPlannedOnboardingApis,
  getUnManagedVmApi,
  updateBulkScheduleApi
} from '../api';
// local import
import { getNumberOfInstanceFromPyaload } from '../utils/helper';
import UnmanagedVmInstrumentation from '../utils/instrumentation';
import * as slice from './unmanagedVm.slice';

const unmanagedVmInstrumentation = new UnmanagedVmInstrumentation();

export function* onGetUnManagedVm({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getUnManagedVmApi(payload));
    yield put(slice.getUnManagedVmSuccess(data));
  } catch (e: any) {
    yield put(slice.getUnManagedVmError(e));
  }
}

export function* onGetMirrorMetrics({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getMirrorInProgressMetricsApis(payload));
    yield put(slice.geMirrorInprogressMetricsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getMirrorInprogressMetricsRejected(e));
  }
}

export function* onGetPlannedOnboaring(): SagaIterator {
  try {
    const data = yield call(() => getPlannedOnboardingApis());
    yield put(slice.getPlannedOnboardingSuccess(data));
  } catch (e: any) {
    yield put(slice.getPlannedOnboardingError(e));
  }
}

export function* onCreateBulkSchedule({ payload }: any): SagaIterator {
  unmanagedVmInstrumentation.onboardingInitiated();
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_create'
  };

  try {
    const data = yield call(() => createBulkScheduleApi(payload));
    yield put(slice.createBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);
  } catch (e: any) {
    yield put(slice.createBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });
  }
}

export function* onUpdateBulkSchedule({ payload }: any): SagaIterator {
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_update'
  };

  try {
    const data = yield call(() => updateBulkScheduleApi(payload));
    yield put(slice.updateBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);
  } catch (e: any) {
    yield put(slice.updateBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });
  }
}

export function* onCancelBulkSchedule({ payload }: any): SagaIterator {
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_cancel'
  };

  try {
    const data = yield call(() => cancelBulkScheduleApi(payload));
    yield put(slice.cancelBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);
  } catch (e: any) {
    yield put(slice.cancelBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });
  }
}

// Watcher Saga
export function* unManagedVmWatcherSagas(): SagaIterator {
  yield takeLatest('unmanagedvm/getUnManagedVm', onGetUnManagedVm);
  yield takeLatest('unmanagedvm/getMirrorInprogressMetrics', onGetMirrorMetrics);
  yield takeLatest('unmanagedvm/bulkScheduleInit', onCreateBulkSchedule);
  yield takeLatest('unmanagedvm/getPlannedOnboarding', onGetPlannedOnboaring);
  yield takeLatest('unmanagedvm/updatebulkScheduleInit', onUpdateBulkSchedule);
  yield takeLatest('unmanagedvm/cancelbulkScheduleInit', onCancelBulkSchedule);
}

export default unManagedVmWatcherSagas;
