import { createSlice } from '@reduxjs/toolkit';
// local import
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
import { SummarySliceType } from '../types';

const initialState: SummarySliceType = {
  summaryaData: null,
  inProcess: undefined,
  error: null
};

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    getSummaryPending: (state, { payload }) => {
      state.inProcess = true;
    },
    getSummaryFulfilled: (state, action) => {
      state.inProcess = false;
      state.summaryaData = action.payload;
    },
    getSummaryRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'summary-error'});
      state.inProcess = false;
      state.error = action.payload ?? null;
    }
  }
});

export const { getSummaryPending, getSummaryFulfilled, getSummaryRejected } = summarySlice.actions;

export default summarySlice.reducer;
