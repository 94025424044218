import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// image
import accounts from 'assets/images/layout/accounts.svg';
import accountsActive from 'assets/images/layout/accountsActive.svg';
import Audit from 'assets/images/layout/audit.svg';
import AuditActive from 'assets/images/layout/auditActive.svg';
import Dashboard from 'assets/images/layout/dashboard.svg';
import DashboardActive from 'assets/images/layout/dashboardActive.svg';
import LucidityLogo from 'assets/images/layout/lucidityLogo.svg';
import Onboard from 'assets/images/layout/onboard.svg';
import OnboardActive from 'assets/images/layout/onboardActive.svg';
import Scaler from 'assets/images/layout/scalar.svg';
import ScalerActive from 'assets/images/layout/scalarActive.svg';
import Kubernetes from "assets/images/layout/kubernetes.svg";
import KubernetesActive from "assets/images/layout/KubernetesActive.svg"
import userActive from 'assets/images/layout/user.svg';
import userInActive from 'assets/images/layout/user_inactive.svg';
// local import
import { CustomLayoutToolTip } from 'components/StylesTooltip';
import SvgIcons from 'components/SvgIcons';
import useLayoutHooks from 'hooks/useLayoutHooks';
import storage from 'utils/storage';

declare const window: Window &
  typeof globalThis & {
    navigator: any;
  };
window.localStorage.setItem('lucidity-userType', '0');

const LeftNav: FC = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState<any>({});
  const isnewUser = storage.getIsNewUser();
  const { isEnable } = useLayoutHooks('tabs', ['Authorization', 'Accounts', 'Audit', 'Kubernetes']);

  const handleTTClose = (route: string) => {
    if (isnewUser) setOpen({ [route]: false });
  };
  const handleTTOpen = (route: string) => {
    if (isnewUser) setOpen({ [route]: true });
  };

  return (
    <>
      <List sx={{ alignItems: 'center' }}>
        <ListItem disablePadding sx={{ flexDirection: 'column' }} className="lucidity_logo">
          <SvgIcons src={LucidityLogo} width={51} height={41} />
        </ListItem>

        <Box
          sx={{ height: 'calc(100vh - 374px)' }}
          className={`${isnewUser ? 'blur-sidebar' : null}`}>
          <ListItem disablePadding className="menu_style">
            <CustomLayoutToolTip
              text={'Sorry you do not have access to this feature.'}
              title={'Dashboard'}
              ttOpen={open?.dashboard && isnewUser ? true : false}
              handleTTClose={() => handleTTClose('dashboard')}
              handleTTOpen={() => handleTTOpen('dashboard')}>
              <NavLink
                data-testid="dashboard-link"
                to="dashboard"
                className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
                <ListItemButton sx={{ flexDirection: 'column' }}>
                  <SvgIcons
                    src={pathname === '/dashboard' ? DashboardActive : Dashboard}
                    width={21.5}
                    height={22}
                  />
                  <ListItemText primary={'Dashboard'} sx={{ textAlign: 'center', m: 0 }} />
                </ListItemButton>
              </NavLink>
            </CustomLayoutToolTip>
          </ListItem>

          <ListItem disablePadding className="menu_style">
            <CustomLayoutToolTip
              text={'Sorry you do not have access to this feature.'}
              title={'Scaler'}
              ttOpen={open?.scaler && isnewUser ? true : false}
              handleTTClose={() => handleTTClose('scaler')}
              handleTTOpen={() => handleTTOpen('scaler')}>
              <NavLink
                to="scaler"
                className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
                <ListItemButton sx={{ flexDirection: 'column' }}>
                  <SvgIcons
                    src={pathname?.includes('scaler') ? ScalerActive : Scaler}
                    width={24}
                    height={24}
                  />
                  <ListItemText primary={'Scaler'} sx={{ textAlign: 'center', m: 0 }} />
                </ListItemButton>
              </NavLink>
            </CustomLayoutToolTip>
          </ListItem>

          <ListItem disablePadding className="menu_style">
            <CustomLayoutToolTip
              text={'Sorry you do not have access to this feature.'}
              title={'Onboard Disk'}
              ttOpen={open?.onboard && isnewUser ? true : false}
              handleTTClose={() => handleTTClose('onboard')}
              handleTTOpen={() => handleTTOpen('onboard')}>
              <NavLink
                to="onboard"
                className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
                <ListItemButton sx={{ flexDirection: 'column' }}>
                  <SvgIcons
                    src={pathname?.includes('onboard') ? OnboardActive : Onboard}
                    width={24}
                    height={24}
                  />
                  <ListItemText primary={'Onboard Disk'} sx={{ textAlign: 'center', m: 0 }} />
                </ListItemButton>
              </NavLink>
            </CustomLayoutToolTip>
          </ListItem>

          {isEnable['Kubernetes'] && (
            <ListItem disablePadding className="menu_style">
              <CustomLayoutToolTip
                text={'Sorry you do not have access to this feature.'}
                title={'Kubernetes'}
                ttOpen={open?.kubernetes && isnewUser ? true : false}
                handleTTClose={() => handleTTClose('kubernetes')}
                handleTTOpen={() => handleTTOpen('kubernetes')}>
                <NavLink
                  to="kubernetes"
                  className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
                  <ListItemButton sx={{ flexDirection: 'column' }}>
                    <SvgIcons
                      src={pathname?.includes('kubernetes') ? KubernetesActive : Kubernetes}
                      width={24}
                      height={24}
                    />
                    <ListItemText primary={'Kubernetes'} sx={{ textAlign: 'center', m: 0 }} />
                  </ListItemButton>
                </NavLink>
              </CustomLayoutToolTip>
            </ListItem>
          )}


        </Box>
      </List>
      <List sx={{ alignItems: 'center', position: 'absolute', bottom: '12px' }}>
        {isEnable['Authorization'] && (
          <ListItem disablePadding className="menu_style">
            <NavLink
              to="users"
              className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
              <ListItemButton sx={{ flexDirection: 'column' }}>
                <SvgIcons
                  src={pathname?.includes('users') ? userActive : userInActive}
                  width={24}
                  height={24}
                />
                <ListItemText primary={'Users'} sx={{ textAlign: 'center', m: 0 }} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        )}
        {isEnable['Accounts'] && (
          <ListItem disablePadding className="menu_style">
            <NavLink
              to="accounts"
              className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
              <ListItemButton sx={{ flexDirection: 'column' }}>
                <SvgIcons
                  src={pathname?.includes('accounts') ? accountsActive : accounts}
                  width={24}
                  height={24}
                />
                <ListItemText primary={'Accounts'} sx={{ textAlign: 'center', m: 0 }} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        )}
        {isEnable['Audit'] && (
          <ListItem disablePadding className="menu_style">
            <NavLink
              to="audit/dashboard"
              className={({ isActive }) => (isActive ? 'active_nav_link' : 'active_nav')}>
              <ListItemButton sx={{ flexDirection: 'column' }}>
                <SvgIcons
                  src={pathname?.includes('audit') ? AuditActive : Audit}
                  width={24}
                  height={24}
                />
                <ListItemText primary={'Audit'} sx={{ textAlign: 'center', m: 0 }} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default LeftNav;
