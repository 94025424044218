import { useState } from 'react';

const useModal = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return {
    openModal,
    setOpenModal,
    handleModalClose
  };
};

export default useModal;
