import { FC } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { isTokenAndAuthTypeValid, isUndefineNull } from 'utils/common';
import storage from 'utils/storage';

const PublicRoute: FC = () => {
  return <Outlet />;
};

export default PublicRoute;
