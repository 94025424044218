import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import config from 'config';
import { EffectCallback, FC, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import storage from 'utils/storage';
import { oktaConfig } from '../config/oktaConfig';
import { Box, Typography } from '@mui/material';
import AzureLogin from './AzureLogin';
import oktaInstrumentation from '../utils/instrumentation/okta';

const OktaSignInWidget: FC = () => {
  const oktaAnalytics = oktaInstrumentation.initialize();
 
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef<any>();
  const queryParams = new URLSearchParams(window.location.search);
  const state: string = queryParams.get('state') || '';
  const [isForgotpw, setIsForgotpw] = useState<boolean>(false);

  useEffect((): ReturnType<EffectCallback> => {
    const issuer = oktaConfig.issuer;
    const scopes = oktaConfig.scopes;
    const widget = new OktaSignIn({
      baseUrl: oktaConfig.issuer.split('/oauth2')[0],
      clientId: oktaConfig.clientId,
      redirectUri: oktaConfig.redirectUri,
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Lucidity'
        }
      },
      authParams: {
        issuer,
        scopes
      },
      state: state,
      idpDisplay: 'SECONDARY',
      idps: [
        {
          type: 'GOOGLE',
          id: config.oktaIdp
        }
      ]
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res: any) => {
        const tokenDetail: any = res?.tokens?.accessToken;
        storage.setTokenExpire(tokenDetail?.expiresAt);
        storage.setAuthType('okta');
        storage.setToken(tokenDetail?.accessToken);
        oktaAuth.handleLoginRedirect(res?.tokens);
        oktaAuth.tokenManager.add('accessToken', tokenDetail);

        oktaAnalytics.authSuccess({ tokenResponse: res })
      },
      (err: any) => {
        oktaAnalytics.authError({ error: err })
        console.log('error msg:' + err.message);
        toast.error(err.message, { id: 'okta-widget-error' });
      }
    );

    const isCorsError = (err: any) => err.name === 'AuthApiError' && !err.statusCode;

    widget.on('afterError', (_context: any, error: any) => {
      if (isCorsError(error)) {
        toast.error('cors error', { id: 'okta-cors-error' });
      }
      oktaAnalytics.authError({ error })
    });

    widget.on('afterRender', () => {
      const forgotpw = document.querySelector('.forgot-password');
      const buttonWide = document.querySelector('.button-wide');
      const azueButton = document.querySelector('.azue_button') as HTMLElement;
      const userNameLabel = document.querySelector('[for=okta-signin-username]');
      if (userNameLabel) {
        userNameLabel.innerHTML = 'Email';
      }
      if ((forgotpw || buttonWide) && azueButton) {
        setIsForgotpw(true);
        azueButton.style.display = 'none';
      } else {
        setIsForgotpw(false);
        if (azueButton) azueButton.style.display = 'block';
      }

      oktaAnalytics.attachRequestListener();
    });

    return (): any => widget.remove();
  }, [oktaAuth]);

  return (
    <Box
      sx={{
        width: '420px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
      <Typography variant="h2" gutterBottom fontSize={36} lineHeight="48px" mb={4.5}>
        {isForgotpw ? 'Reset Password' : 'Sign in to Lucidity'}
      </Typography>
      <div ref={widgetRef} />
      <AzureLogin />
    </Box>
  );
};

export default OktaSignInWidget;
