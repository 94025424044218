import { Box, Divider } from '@mui/material';
import GlobalSpinner from 'components/GlobalSpinner';
import NovmsMessage from 'features/scalar/components/shared/components/NovmsMessage';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPlannedOnboarding, resetBulkOnboardSchedulePayload } from '../../../store';
import PlannedScheduleLabel from '../plannedScheduleLabel';
import PlannedVmDetailTable from '../plannedVmDetailsTable';
import Title from '../title/Title';

const PlannedOnbardingContent: FC = () => {
  const dispatch = useAppDispatch();
  const { plannedOnboarding } = useAppSelector(
    (state) => state.unmanagedVm
  );
  const [scheduledInstanceIndex, setScheduledInstanceIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(resetBulkOnboardSchedulePayload());
    dispatch(getPlannedOnboarding());
  }, []);

  const handleScheduleLabel = (index: number) => {
    setScheduledInstanceIndex(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Title title="Scheduled Onboarding" />
      {plannedOnboarding?.status === 'loading' && <GlobalSpinner height="40vh" />}
      {plannedOnboarding?.plannedOnboardingData?.data && (
        <>
          <Divider sx={{ mt: 3 }} />
          {plannedOnboarding?.plannedOnboardingData?.data?.bulkScheduleTriggerResponseList
            ?.length === 0 ? (
              <Box sx={{px: 4, py: 8}}>
            <NovmsMessage title='No Onboarding Scheduled' hideSubTtitle/>
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Box
                  sx={{
                    flexBasis: '20%',
                    minHeight: 500,
                    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                    pb: 4
                  }}>
                  <PlannedScheduleLabel
                    handleScheduleLabel={handleScheduleLabel}
                    scheduledInstanceIndex={scheduledInstanceIndex}
                  />
                </Box>
                <Box sx={{ flexBasis: '80%', pr: 3, pb: 4 }}>
                  <PlannedVmDetailTable scheduledInstanceIndex={scheduledInstanceIndex} />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PlannedOnbardingContent;
