import axios from 'axios';
import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { AuditFilePayload, FileStatusPayload } from '../types';

export const downloadAduitDoc = () => {
  return axios.get(
    'https://audittool.s3.ap-south-1.amazonaws.com/client/doc/Azure+Audit+Tool+Document.pdf',
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      }
    }
  );
};

export const uploadReportApi = (payload: AuditFilePayload): Promise<ApiResponse<string>> =>
  ApiService.post<string>(`/api/v1/audit/report/upload`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
