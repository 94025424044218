import { Box } from '@mui/material';
import { CartesianGrid, Label, Line, LineChart, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { useAppSelector } from 'store/hooks';
import { getConvertedLocalTime } from 'utils/common';
import reboot from '../../../../assets/images/reboot.svg';
import GraphInfo from './GraphInfo';

const InProgressGraph = () => {
  const { mirrorMetrics } = useAppSelector(state => state.unmanagedVms)

  return (
    <Box
      sx={{
        width: '740px'
      }}>
      <GraphInfo {...mirrorMetrics} />

      {mirrorMetrics?.data?.progressGraphMetrics && (
        <LineChart
          width={740}
          height={350}
          data={mirrorMetrics?.data?.progressGraphMetrics}
          margin={{ top: 36, left: -10, bottom: 20, right: 10 }}
        >
          <CartesianGrid strokeDasharray="0" strokeWidth={0.8} stroke="#454546" strokeOpacity={0.8} />
          <XAxis
            dataKey="timestamp"
            style={{ fontSize: '10px', lineHeight: '26px' }}
            axisLine={{ stroke: 'rgba(221, 255, 85, 0.20)' }}
            stroke="#fff"
            scale="time"
            type="number"
            domain={[
              mirrorMetrics?.data?.progressGraphMetrics[0]?.timestamp,
              mirrorMetrics?.data?.progressGraphMetrics?.[mirrorMetrics?.data?.progressGraphMetrics?.length - 1]?.timestamp
            ]}
            tickLine={false}
            tick={<CustomizedTick />}
          >
            <Label
              value="Time"
              style={{ fontSize: '10px' }}
              offset={-10}
              position="insideBottom"
              fill="#fff"
            />
          </XAxis>

          <YAxis
            style={{ fontSize: '10px' }}
            axisLine={{ stroke: 'rgba(221, 255, 85, 0.20)' }}
            stroke="#fff"
            tickCount={12} type="number" domain={[0, 100]}
            tickLine={false}
            tick={<CustomizedGroupTick />}
          >
            <Label
              fill="#fff"
              offset={0}
              style={{ fontSize: '10px' }}
              value="Completion (%)"
              position="insideStart"
              transform="rotate(-90 0 163)"
            />
          </YAxis>

          <Tooltip
            itemStyle={{ color: '#212529' }}
            cursor={false}
            content={<CustomTooltip />}
          />
          <Line
            dot={<CustomizedDot />}
            activeDot={{strokeWidth: 0, r: 3}}
            isAnimationActive={false}
            animationEasing="linear"
            connectNulls
            name="Progress"
            type="monotone"
            dataKey="value"
            stroke="#DDFF55"
            fill="rgba(24, 160, 251, 0.7)"
            ></Line>
        </LineChart>
      )}
    </Box>
  );
};

export default InProgressGraph;

const CustomizedDot = (props: any) => {
  const { cx, cy, payload } = props;

  if (isRebooted(payload?.eventSubType)) {
    return (
      <svg x={cx - 50} y={cy - 50} height="100" width="100" xmlns="http://www.w3.org/2000/svg">
        <circle r="8" cx="50" cy="50" fill="#ddff55" />
        <image x={45} y={45} width={10} height={10} xlinkHref={reboot} />
      </svg>
    );
  }

  return (
    <></>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
  if (active && payload) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(31, 32, 35, 0.88)',
          padding: '14px',
          borderRadius: 4,
          border: '1px solid #454546',
          minWidth: '200px',
          '& p': { fontSize: '14px', color: 'rgba(255, 255, 255, 0.5)' },
          '& span': { fontSize: '14px' }
        }}>
        {isRebooted(payload?.[0]?.payload?.eventSubType) && <div>
          <span>{payload?.[0]?.payload?.eventSubType === 'SCHEDULED_REBOOT' ? 'Scheduled reboot' : 'Unscheduled reboot'}</span>
          <p>Time: {label && label > 0 ? getConvertedLocalTime(label)?.toLocaleString('en-GB') : ''}</p>
          <p>
            Progress impact: {payload?.[0]?.payload?.dropInProgress}%
          </p>
        </div>}

        {payload?.[0]?.payload?.eventType === 'PROGRESS' && <>
          <p>Time: {label && label > 0 ? getConvertedLocalTime(label)?.toLocaleString('en-GB') : ''}</p>
          <p>
            {payload?.[0]?.name}: {payload?.[0]?.value}%
          </p>
        </>}
      </Box>
    );
  } else {
    return <></>;
  }
};

export const CustomizedTick = (props: any) => {
  const { x, y, payload } = props;
  const date = payload ? getConvertedLocalTime(payload.value) : undefined;
  const dateOrTime = date?.toLocaleTimeString('en-GB');

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={14} y={0} dy={10} textAnchor="end" fill="#fff" fontSize={10}>
        {dateOrTime?.substring(0, 5)}
      </text>
    </g>
  );
};

const CustomizedGroupTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g>
      <g>
        <text x={x - 20} y={y} fill="#fff" fontSize={10}>
          {payload?.value + '%'}
        </text>
      </g>
    </g>
  );
};

const isRebooted = (eventSubType: string) => {
  return eventSubType === 'SCHEDULED_REBOOT' || eventSubType === 'CUSTOMER_REBOOT'
}