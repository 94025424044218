import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import config, { environmentName } from 'config';
import { useAppSelector } from 'store/hooks';

const useAnalytics = () => {
  const { tenantData } = useAppSelector((state) => state.tenants);
  const identifyEvent = new amplitude.Identify();

  useEffect(() => {
    if (config.amplitudeKey) {
      amplitude.init(config.amplitudeKey, {
        defaultTracking: {
          formInteractions: false
        }
      });
    }
  }, []);

  const isPushAnalytics = () => {
    if (tenantData?.userInfo?.email.includes('@lucidity')) {
      identifyEvent.set('user_type', 'internal');
      if (environmentName === 'dev' || environmentName === 'uat') {
        return true;
      } else {
        return false; 
      }
    } else {
      identifyEvent.set('user_type', 'external');
      return true;
    }
  };

  useEffect(() => {
    if (isPushAnalytics()) {
      amplitude.setOptOut(false);
      if (tenantData?.accountId) {
        identifyEvent.set('account_id', tenantData?.accountId);
        amplitude.identify(identifyEvent);
      }
      if (tenantData?.userInfo) amplitude.setUserId(tenantData?.userInfo.email);
    } else {
      amplitude.setOptOut(true);
    }
  }, [tenantData]);

  return {
    identifyEvent
  };
};

export { useAnalytics };
