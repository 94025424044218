import * as amplitude from "@amplitude/analytics-browser";

class Instrumentation {
  public eventName: string

  constructor(eventName: string) {
    this.eventName = eventName
  }

  pushEvent = (eventProps: any) => {
    amplitude.track(this.eventName, eventProps)
  }
}

export default Instrumentation;