import { createSlice } from '@reduxjs/toolkit';
// local import
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
import { toastErrorMsg } from 'utils/common';
import { amplitudeTrack } from '../../shared/utils';
import { ManagedVmsSliceType } from '../types';

const initialState: ManagedVmsSliceType = {
  scalarMetricsData: undefined,
  managedVmsData: undefined,
  operationTimeline: undefined,
  inProcessMetrics: undefined,
  inProcessVms: undefined,
  inProcessOpTl: undefined,
  error: undefined
};

const managedVmsSlice = createSlice({
  name: 'managedvm',
  initialState,
  reducers: {
    getScalarMetrics: (state, { payload }) => {
      state.inProcessMetrics = true;
    },
    getScalarMetricsFulfilled: (state, action) => {
      state.inProcessMetrics = false;
      state.scalarMetricsData = action.payload;
    },
    getScalarMetricsRejected: (state, action) => {
      state.inProcessMetrics = false;
      state.error = action.payload ?? null;
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'metrics-error' });
    },

    getManagedVms: (state, { payload }) => {
      state.inProcessVms = true;

      if (payload.searchQuery) {
        const searchEP = {
          search_query: payload.searchQuery,
          search_type: 'request'
        };
        amplitudeTrack('managed_search', searchEP);
      }
      if (payload.sort) {
        const sortEP = {
          sort_query: payload.sort,
          sort_type: 'request'
        };
        amplitudeTrack('managed_sort', sortEP);
      }
    },
    getManagedVmsFulfilled: (state, action) => {
      state.managedVmsData = action.payload?.data;
      state.inProcessVms = false;

      if (action.payload?.userprops?.searchQuery) {
        const searchEP = {
          search_type: 'response',
          search_result: action?.payload.data.length > 0
        };
        amplitudeTrack('managed_search', searchEP);
      }
      if (action.payload?.userprops?.sort) {
        const sortEP = {
          sort_type: 'response',
          sort_result: action?.payload.data.length > 0
        };
        amplitudeTrack('managed_sort', sortEP);
      }
    },
    getManagedVmsRejected: (state, action) => {
      state.inProcessVms = false;
      state.error = action.payload ?? null;
      state.managedVmsData = undefined;
      toastErrorMsg(action.payload?.e, 'managed-vm-error');

      if (action.payload?.userprops?.searchQuery) {
        const searchEP = {
          search_type: 'response',
          search_result: false,
          search_query: action.payload?.userprops?.searchQuery,
          search_error_detail: `${action.payload?.e}`
        };
        amplitudeTrack('managed_search', searchEP);
      }
      if (action.payload?.userprops?.sort) {
        const sortEP = {
          sort_type: 'response',
          sort_result: false,
          sort_query: action.payload?.userprops?.sort,
          sort_error_detail: `${action.payload?.e}`
        };
        amplitudeTrack('managed_sort', sortEP);
      }
    },

    getOperationalTimeline: (state, { payload }) => {
      state.inProcessOpTl = true;
    },
    getOperationalTimelineFulfilled: (state, action) => {
      state.inProcessOpTl = false;
      state.operationTimeline = action.payload;
    },
    getOperationalTimelineRejected: (state, action) => {
      state.inProcessOpTl = false;
      state.error = action.payload ?? null;
      toastErrorMsg(action.payload, 'operational-Timeline');
    }
  }
});

export const {
  getScalarMetrics,
  getScalarMetricsFulfilled,
  getScalarMetricsRejected,
  getManagedVms,
  getManagedVmsFulfilled,
  getManagedVmsRejected,
  getOperationalTimeline,
  getOperationalTimelineFulfilled,
  getOperationalTimelineRejected
} = managedVmsSlice.actions;

export default managedVmsSlice.reducer;
