import { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { ProgressContainer } from './ProgressContainer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetCloudEntityStatusesList,
  startPollingGetCloudEntityStatusesList,
  stopPollingGetCloudEntityStatusesList
} from 'features/accounts/store';
import Buttons from 'components/buttons';
import CustomToolTip from 'components/StylesTooltip';
import GlobalSpinner, { GlobalBarLoader } from 'components/GlobalSpinner';
import { getTenantPending } from 'features/auth/store';

const TrackProgress = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cloudEntityStatusesList, cloudEntityStatusNumbers, cloudType, polling } = useAppSelector(
    (state) => state.accounts
  );
  const isOnbardingCompleted = cloudEntityStatusNumbers?.completionPercentage === 100;

  useEffect(() => {
    const payload = { cloudProvider: cloudType, pollingDelay: polling.delay };
    dispatch(startPollingGetCloudEntityStatusesList(payload));
    return () => {
      dispatch(stopPollingGetCloudEntityStatusesList());
      dispatch(resetCloudEntityStatusesList());
    };
  }, []);

  useEffect(() => {
    if (!polling.delay && !polling.loading) dispatch(stopPollingGetCloudEntityStatusesList());
  }, [polling.delay]);

  const handleSubmit = () => {
    if (isOnbardingCompleted) {
      dispatch(getTenantPending());
      navigate('/accounts');
    }
  };

  return (
    <>
      <Box gap={2} sx={{ display: 'flex', marginTop: '32px', minWidth: '960px' }}>
        {polling.loading ? (
          <Grid container>
            <Grid item xs={10}>
              <GlobalSpinner data-testid="globar-spinner" height="400px" />
            </Grid>{' '}
          </Grid>
        ) : (
          <>
            <ProgressContainer />
            <Grid item xs={8} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
              <List sx={{ borderRadius: 2, backgroundColor: 'rgba(255, 255, 255, 0.05))' }}>
                {cloudEntityStatusesList?.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      my: 1,
                      borderRadius: '8px',
                      marginTop: index === 0 ? 0 : '',
                      border: '1px solid #454546'
                    }}>
                    <ListItemIcon>
                      {item.status === 'SUCCESS' ? (
                        <CheckCircleIcon color="success" />
                      ) : item.status === 'FAILED' ? (
                        <WarningIcon sx={{ color: '#E15D5D' }} />
                      ) : (
                        <CircularProgress size="25px" />
                      )}
                      <Box sx={{ width: '1px', bgcolor: '#565656', height: '32px', ml: 2 }}></Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.cloudEntityName}
                      sx={{
                        color: item.status === 'FAILED' ? 'error.main' : 'inherit'
                      }}
                    />
                    {(item.status === 'IN_PROGRESS' || item.status === 'PENDING') && (
                      <GlobalBarLoader CircleType="bar" />
                    )}
                    {item.status === 'FAILED' && (
                      <Box display="flex">
                        <Typography variant="body2" color="#E15D5D" mx={3}>
                          {item.category}
                        </Typography>
                        <CustomToolTip text={item.reason} />
                      </Box>
                    )}
                    {item.status === 'SUCCESS' && (
                      <Box display="flex" gap={1} sx={{ color: 'rgba(255, 255, 255, 0.80)' }}>
                        <Typography variant="subtitle2" sx={{ mt: '2px' }}>
                          Instance: {item.vmCount}
                        </Typography>
                        <Typography>•</Typography>
                        <Typography variant="subtitle2" sx={{ mt: '2px' }}>
                          Disks: {item.diskCount}
                        </Typography>
                      </Box>
                    )}
                  </ListItem>
                ))}
              </List>
            </Grid>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '2px',
          width: '100%',
          left: 0,
          bottom: 0,
          position: 'fixed',
          justifyContent: 'flex-end',
          background: 'rgb(35, 36, 38)',
          padding: '15px'
        }}>
        {
          <Buttons
            disabled={!isOnbardingCompleted}
            btnVarient="contained"
            text="Finish"
            onClick={handleSubmit}
            sx={{ width: 100, mr: 2 }}
          />
        }
      </Box>
    </>
  );
};

export default TrackProgress;
