import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';

interface TimePickerType {
  timeValue: Date | string | number | null;
  handleTime: (e: any) => void;
  onPickerError?: (e: any) => void;
  minTime?: Date | string;
  disablePast?: boolean
}
const TimePickers: FC<TimePickerType> = ({ timeValue, handleTime, onPickerError, disablePast, minTime }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        data-testid="mui-time-picker"
        disablePast={disablePast}
        views={['hours', 'minutes', 'seconds']}
        ampm={false}
        value={timeValue}
        onChange={handleTime}
        onError={onPickerError}
        slotProps={{
          layout: {
            sx: {
              '.MuiPickersToolbar-root, .MuiPickersLayout-contentWrapper, .MuiDialogActions-root': {
                backgroundColor: '#454546'
              }
            }
          }
        }}
        minTime={minTime}
        timeSteps={{ minutes: 1 }}
      />
    </LocalizationProvider>
  );
};

export default TimePickers;
