import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getSummary } from '../api';
// local import
import { getSummaryFulfilled, getSummaryRejected } from '../store';

export function* onGetSummary({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getSummary(payload));
    yield put(getSummaryFulfilled(data));
  } catch (e: any) {
    yield put(getSummaryRejected(e));
  }
}

// Watcher Saga
export function* summaryWatcherSaga(): SagaIterator {
  yield takeEvery('summary/getSummaryPending', onGetSummary);
}

export default summaryWatcherSaga;
