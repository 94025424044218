const useCookies = () => {
  const addCookie = (key: string, value: string | any, numberOfDays = 30) => {
    const now = new Date();
    now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
    if (typeof document !== 'undefined') {
      document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
    }
  };

  const readCookie = (key: string) => {
    if (typeof document !== 'undefined') {
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      let formCookie;
      cookies.forEach((cookie) => {
        if (cookie?.trim()?.startsWith(`${key}=`)) {
          formCookie = cookie.replace(`${key}=`, '').trim();
        }
      });
      return formCookie;
    }
  };
  return { addCookie, readCookie };
};

export { useCookies };
