import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC } from 'react';

interface DatePickerType {
  dateValue: any;
  handleDate: (e: any) => void;
  onPickerError?: (e: any) => void;
  minDate?: Date
}

const DatePickers: FC<DatePickerType> = ({ dateValue, handleDate, onPickerError, minDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        data-testid="mui-date-picker"
        disablePast={true}
        value={dateValue}
        minDate={minDate ? minDate : undefined}
        onChange={handleDate}
        onError={onPickerError}
        slotProps={{
          layout: {
            sx: {
              '.MuiDateCalendar-root': {
                color: '#bbdefb',
                border: '1px solid #454546',
                backgroundColor: '#454546'
              }
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickers;
