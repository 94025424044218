import AuthInstrumentation from "utils/instrumentation";

class OktaAuthInstrumentation extends AuthInstrumentation {
  currentAuthIdpBtn: string
  authProviderPrimary: string
  authProviderSecondary: string
  authProviderSaml: string
  static authProviderSecondaryBtnIdentifier = '[data-se="social-auth-google-button"]'
  static authProviderPrimaryBtnIdentifier = '#okta-signin-submit'
  static eventName = 'sign_in'

  constructor() {
    super(OktaAuthInstrumentation.eventName)

    this.currentAuthIdpBtn = ''
    this.authProviderPrimary = 'okta_email_pass'
    this.authProviderSecondary = 'okta_google_oauth'
    this.authProviderSaml = 'okta_saml'
  }

  dispatchRequest = (authProvider: string, extraData: any = {}) => {
    this.pushEvent({
      type: 'request',
      auth_provider: authProvider,
      ...extraData
    })
  }

  dispatchSamlRequest = () => {
    this.dispatchRequest(this.authProviderSaml)
  }

  dispatchResponse = ({ email, authProvider }: { email: string | undefined, authProvider: string }) => {
    this.pushEvent({
      type: 'response',
      status: 'success',
      auth_provider: authProvider,
      user_email: email,
    })
  }

  authSuccess = ({ tokenResponse }: { tokenResponse: any }) => {
    const {
      claims: {
        sub: email
      }
    } = tokenResponse.tokens.accessToken
    this.dispatchResponse({ email, authProvider: this.getAuthProviderBasisLatestTouchpoint() })    
  }

  getAuthProviderBasisLatestTouchpoint = () => {
    return this.currentAuthIdpBtn === 'google_oauth' 
      ? this.authProviderSecondary : this.authProviderPrimary
  }

  samlSuccess = ({ email }: { email: string | undefined }) => {
    this.dispatchResponse({
      email,
      authProvider: this.authProviderSaml
    })
  }

  authError = ({ error, isSaml }: { error: any, isSaml?: boolean }) => {
    this.pushEvent({
      type: 'response',
      auth_provider: isSaml ? this.authProviderSaml : this.getAuthProviderBasisLatestTouchpoint(),
      status: 'failed',
      status_detail: error?.xhr?.responseText || error?.message || `${JSON.stringify(error)}`,
    })
  }

  attachRequestListener = () => {
    const googleAuthBtn = document.querySelector(
      OktaAuthInstrumentation.authProviderSecondaryBtnIdentifier
    ) as HTMLButtonElement
    const emailPassSignInBtn = document.querySelector(
      OktaAuthInstrumentation.authProviderPrimaryBtnIdentifier
    ) as HTMLButtonElement
  
    if(googleAuthBtn) {
      googleAuthBtn.onclick = () => {
        this.currentAuthIdpBtn = 'google_oauth'
        this.dispatchRequest(this.authProviderSecondary)
      }
    }

    if(emailPassSignInBtn) {
      this.currentAuthIdpBtn = 'email_pass'
      const emailInput = document.getElementById('okta-signin-username') as HTMLInputElement

      emailPassSignInBtn.onclick = () => {
        this.dispatchRequest(this.authProviderPrimary,{
          user_email: emailInput?.value
        })
      }
    }
  }
}

function initialize() {
  return new OktaAuthInstrumentation();
}

export default {
  initialize
}