import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
// local import
import { UnManagedVmsSliceType } from '../types';
import { toastErrorMsg } from 'utils/common';
import { amplitudeTrack } from '../../shared/utils';

const initialState: UnManagedVmsSliceType = {
  unmanagedVmsData: undefined,
  inProcess: undefined,
  error: undefined,
  inProcessCleanup: undefined,
  multiPartitionData: {
    inProcess: undefined,
    multiPartitions: undefined
  },
  mirrorMetrics: {
    inProgress: null,
    data: null
  }
};

const unmanagedVmsSlice = createSlice({
  name: 'unmanagedvm',
  initialState,
  reducers: {
    getUnManagedVms: (state, { payload }) => {
      state.inProcess = true;
    },
    getUnManagedVmsFulfilled: (state, action) => {
      state.unmanagedVmsData = action.payload;
      state.inProcess = false;
    },
    getUnManagedVmsRejected: (state, action) => {
      toast.error(action.payload, { id: 'unmanaged' });
      state.inProcess = false;
      state.error = action.payload ?? null;
      state.unmanagedVmsData = undefined
    },

    getSearchUnManagedVms: (state, { payload }) => {
      const eventProperties = {
        search_query: payload.searchQuery,
        search_type: 'request'
      };
      amplitudeTrack('unmanaged_search', eventProperties)
      state.inProcess = true;
    },
    getSearchUnManagedVmsFulfilled: (state, action) => {
      state.unmanagedVmsData = action.payload;
      state.inProcess = false;
      const eventProperties = {
        search_type: 'response',
        search_result: action?.payload.data.length > 0
      };
      amplitudeTrack('unmanaged_search', eventProperties)
    },
    getSearchUnManagedVmsRejected: (state, action) => {
      state.inProcess = false;
      state.error = action.payload ?? null;
      state.unmanagedVmsData = undefined
      toastErrorMsg(action.payload, 'search-unmanaged-vm');
      const eventProperties = {
        search_type: 'response',
        search_result: false,
        search_error_detail: `${action.payload?.e}`,
        search_query: action.payload?.userprops?.searchQuery,
      };
      amplitudeTrack('unmanaged_search', eventProperties)
    },

    getMultiPartitions: (state, { payload }) => {
      state.multiPartitionData.inProcess = true;
    },
    getMultiPartitionsFulfilled: (state, action) => {
      state.multiPartitionData.multiPartitions = action.payload;
      state.multiPartitionData.inProcess = false;
    },
    getMultiPartitionsRejected: (state, action) => {
      toast.error(action.payload, { id: 'multi-partition-error' });
      state.multiPartitionData.inProcess = false;
      state.multiPartitionData.multiPartitions = undefined
    },

    getMirrorInprogressMetrics: (state, { payload }) => {
      state.mirrorMetrics.inProgress = true;
    },
    geMirrorInprogressMetricsFulfilled: (state, action) => {
      state.mirrorMetrics.data = action.payload
      state.mirrorMetrics.inProgress = false;
    },
    getMirrorInprogressMetricsRejected: (state, action) => {
      toast.error(action.payload, { id: 'unmanaged' });
      state.mirrorMetrics.inProgress = false;
      state.mirrorMetrics.data = null
    },
  }
});

export const {
  getMirrorInprogressMetrics,
  geMirrorInprogressMetricsFulfilled,
  getMirrorInprogressMetricsRejected,
  getUnManagedVms,
  getUnManagedVmsFulfilled,
  getUnManagedVmsRejected,
  getSearchUnManagedVms,
  getSearchUnManagedVmsRejected,
  getSearchUnManagedVmsFulfilled,
  getMultiPartitions,
  getMultiPartitionsFulfilled,
  getMultiPartitionsRejected
} = unmanagedVmsSlice.actions;

export default unmanagedVmsSlice.reducer;
