import { filters } from 'features/filter/store';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const usePagination = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(0);
  const { requestFilter } = useAppSelector((state) => state.filter);

  useEffect(() => {
    if (requestFilter.pageNo !== undefined) {
      setCurrentPage(requestFilter.pageNo);
    }
  }, [requestFilter]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value - 1);
    dispatch(filters({ pageNo: value - 1 }));
  };

  const handlePageNoChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value - 1);
  };

  return {
    currentPage,
    setCurrentPage,
    handlePageChange,
    pageNo,
    setPageNo,
    handlePageNoChange
  };
};

export default usePagination;
