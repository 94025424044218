import { createTheme } from '@mui/material/styles';
// local import
import 'assets/styles/fonts/font-face-rules.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#161718',
      paper: 'rgba(31, 32, 35, 0.28)'
    },
    primary: {
      light: 'rgba(221, 255, 85, 0.02)',
      main: '#DDFF55',
      dark: '#DDFF55',
      contrastText: '#000000'
    }
  },
  typography: {
    fontFamily: [`biotif-regular`].join(','),
    body1: {
      color: '#ffffff',
      fontWeight: 450,
      fontFamily: 'biotif-book'
    },
    body2: {
      color: '#ffffff',
      fontWeight: 400,
      fontFamily: 'biotif-regular'
    },
    h3: {
      color: '#ffffff',
      fontWeight: 600,
      fontFamily: 'biotif-semibold'
    },
    h2: {
      color: '#ffffff',
      fontWeight: 600,
      fontFamily: 'biotif-semibold'
    },
    h5: {
      color: '#ffffff',
      fontWeight: 500,
      fontFamily: 'biotif-medium'
    },
    h6: {
      color: '#ffffff',
      fontWeight: 700,
      fontFamily: 'biotif-bold'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: 'white'
        }
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            textTransform: 'capitalize',
            borderRadius: '12px',
            fontSize: '18px',
            lineHeight: '24px',
            fontFamily: 'biotif-book',
            fontWeight: 450,
            height: '38px',
            padding: '7px 16px'
          }
        }
      ]
    }
  }
});

export default theme;
