import { createSlice, current } from '@reduxjs/toolkit';
// local import
import {  OnboardSlice } from '../types';
import AlertComponenet from 'components/common/AlertComponents';
import { toast as toasts } from 'react-toastify';
import { MetaProps } from 'types/response/baseResponse.model';

const initialState: OnboardSlice = {
  installCmdData: null,
  instanceList: null,
  inProcess: undefined,
  inProcessListInstance: undefined,
  inProcessAzureInstatnceState: undefined,
  azureInstatnceStateList: undefined,
  error: null
};

const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    getInstallCmd: (state, { payload }) => {
      state.inProcess = true;
    },
    getInstallCmdFulfilled: (state, action) => {
      state.inProcess = false;
      state.installCmdData = action.payload;
    },
    getInstallCmdRejected: (state, action) => {
      state.inProcess = false;
      state.error = action.payload ?? null;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'getInstallCmd-error'});
    },

    // list instance
    getInstanceList: (state, { payload }) => {
      state.inProcessListInstance = true;
    },
    getInstanceListFulfilled: (state, action) => {
      state.inProcessListInstance = false;
      state.instanceList = action.payload;
    },
    getInstanceListRejected: (state, action) => {
      state.inProcessListInstance = false;
      state.instanceList = null;
      state.error = action.payload ?? null;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'getInstanceList-error'});
    },
    resetInstanceList: (state) => {
      state.instanceList = null
    },
    // setup agent install
    setupAgentInstall: (state, { payload }) => {
      state.inProcess = true;
    },
    setupAgentInstallFulfilled: (state, action) => {
      state.inProcess = false;
      state.installCmdData = action.payload;
    },
    setupAgentInstallRejected: (state, action) => {
      state.inProcess = false;
      state.error = action.payload ?? null;
    },
    //get azure instanceState list
    getAzureInstanceStateList: (state,action) => {
      state.inProcessAzureInstatnceState = true; 
    },
    getAzureInstanceStateListFulfilled: (state, action) => {
      state.inProcessAzureInstatnceState = false;
      if(!Array.isArray(action.payload)){
        return
      }
      const updatedData = current(state).instanceList?.data.map(instance => {
        const stateData = action.payload.find((s: any) => s.instanceId === instance.instanceId);
        return {
          ...instance,
          instanceState: stateData ? stateData.instanceState : undefined
        };
      }) || [] 
      const updatedMeta = state?.instanceList?.meta || {} as MetaProps
      state.instanceList = {data: updatedData, meta: updatedMeta}
    },
    getAzureInstanceStateListRejected: (state,action) => {
      state.inProcessAzureInstatnceState = false;
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'getAzureInstanceStateList-error'});
    }
  }
});

export const {
  getInstallCmd,
  getInstallCmdFulfilled,
  getInstallCmdRejected,
  getInstanceList,
  resetInstanceList,
  getInstanceListFulfilled,
  getInstanceListRejected,
  setupAgentInstall,
  setupAgentInstallFulfilled,
  setupAgentInstallRejected,
  getAzureInstanceStateList,
  getAzureInstanceStateListFulfilled,
  getAzureInstanceStateListRejected
} = onboardSlice.actions;

export default onboardSlice.reducer;
