export const stepperData = {
  awsSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Track progress'
    }
  ],
  azureSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Select subscriptions'
    },
    {
      step: 3,
      name: 'Track progress'
    }
  ],
  gcpSteps: [
    {
      step: 1,
      name: 'Authenticate account'
    },
    {
      step: 2,
      name: 'Select Project'
    },
    {
      step: 3,
      name: 'Track progress'
    }
  ]
};
