import { createSlice } from '@reduxjs/toolkit';
// local import
import { FileListSliceType } from '../types';
import { toast as toasts } from 'react-toastify';
import AlertComponenet from 'components/common/AlertComponents';

const initialState: FileListSliceType = {
  fileListData: undefined,
  inProcess: undefined,
  selectedFileName: undefined,
};

const fileListSlice = createSlice({
  name: 'getfilelist',
  initialState,
  reducers: {
    getFileList: (state, { payload }) => {
      state.inProcess = true;
    },
    fileListFulfilled: (state, action) => {
      state.inProcess = false;
      state.fileListData = action.payload
    },
    fileListRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload}/>, {toastId: 'file-filter-error'});
      state.inProcess = false;
    },

    saveSelectedFile: (state, action) => {
      state.selectedFileName =  action.payload
    },
    resetSelectedFile: (state) => {
      state.selectedFileName =  undefined
    }
  }
});

export const { getFileList, fileListFulfilled, fileListRejected, saveSelectedFile, resetSelectedFile } = fileListSlice.actions;

export default fileListSlice.reducer;
