/*
ApiService singleton service for lucidity

response object structure from api service
{ data: {}, status: 200, statusText: 'OK', config: {}, request: {} }
"data" is the response that was provided by the server
"status" is the HTTP status code from the server response
"statusText" is the HTTP status message from the server response
"headers" the HTTP headers that the server responded with
All header names are lower cased and can be accessed using the bracket notation.
Example: "response.headers['content-type']"

"config" is the config object that was provided to "axios" for the request
"request" is an XMLHttpRequest instance in the browser
*/

import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiResponse } from 'types/response/baseResponse.model';
import { isUndefineNull } from 'utils/common';
import storage from 'utils/storage';
import { ApiBaseUrl } from '../config';
import { getItem } from 'utils/localstorage';

export const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: 1000 * 60 * 2,
  responseType: 'json'
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      storage.clearAllStorage();
      throw new Error('unauthorized');
    }
    return response;
  },
  (error) => {
    if (error?.response?.status <= 500) {
      if (error?.response?.status === 401 ) {
        storage.clearAllStorage();
        return Promise.reject('unauthorized');
      }

      if (error?.response?.config.url === '/api/v1/layout/homepage') {
        storage.clearAllStorage();
      }
      return Promise.reject(error?.response?.data?.message);
    }
    return Promise.reject(error.message);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const oktaToken = storage.getToken();
    if (!config?.url?.includes('pending-onboard')) {
      if (
        isUndefineNull(storage.getAuthType()) ||
        (storage.getAuthType() !== 'azure' && isUndefineNull(oktaToken))
      ) {
        storage.clearAllStorage();
        return Promise.reject(new Error());
      }
    }
    (config.headers as AxiosHeaders).set('X-Authtype', String(storage.getAuthType()));
    (config.headers as AxiosHeaders).set(
      'X-tenant',
      storage.getXtenant() ? storage.getXtenant() : ''
    );
    (config.headers as AxiosHeaders).set(
      'X-Account',
      storage.getXAccount() || ''
    );
    (config.headers as AxiosHeaders).set(
      'X-tenants',
      storage.getXTenantIdList() ? storage.getXTenantIdList() : ''
    );
    (config.headers as AxiosHeaders).set('Authorization', `Token ${storage.getToken()}`);
    return config;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

// Singleton service
class LucidityApiService {
  private static instance: LucidityApiService;
  private axios: AxiosInstance;

  responseBody = <T>(response: AxiosResponse<T>) => response.data;

  // constructor private for singleton
  private constructor() {
    this.axios = axiosInstance;
  }

  // returns singleton instance of class
  public static getInstance(): LucidityApiService {
    if (!this.instance) {
      this.instance = new LucidityApiService();
    }
    return this.instance;
  }

  get<T>(url: string, config: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
    return this.axios.get<ApiResponse<T>>(url, config).then(this.responseBody);
  }

  post<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.post<ApiResponse<T>>(url, payload, config).then(this.responseBody);
  }

  put<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.put<ApiResponse<T>>(url, payload, config).then(this.responseBody);
  }

  patch<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.patch<ApiResponse<T>>(url, payload, config).then(this.responseBody);
  }

  delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
    return this.axios.delete<ApiResponse<T>>(url, config).then(this.responseBody);
  }
}

export const ApiService = LucidityApiService.getInstance();
