import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

const useLayoutHooks = (type: string, fieldName: string[]) => {
  const [isEnable, setIsEnable] = useState<{ [x: string]: boolean }>({});
  const [hint, setHint] = useState<string>('');
  const { tenantData, inProcess } = useAppSelector((state) => state.tenants);

  function filterAuth() {
    const data: { [x: string]: boolean } = {};
    if (!inProcess && tenantData) {
      if (type === 'tabs') {
        for (let i = 0; i < tenantData?.tabs?.length; i++) {
          if (
            fieldName.includes(tenantData?.tabs[i].tabName) &&
            tenantData?.tabs[i].enabled === true
          ) {
            data[tenantData?.tabs[i].tabName] = true;
          }
        }
      } else {
        // buttons
        for (let i = 0; i < tenantData?.buttons?.length; i++) {
          if (
            fieldName.includes(tenantData?.buttons[i].text) &&
            tenantData?.buttons[i].enabled === false
          ) {
            data[tenantData?.buttons[i].text] = false;
            setHint(tenantData?.buttons[i]?.hint);
          } else {
            data[tenantData?.buttons[i].text] = true;
          }
        }
      }
    }
    setIsEnable(data);
  }

  useEffect(() => {
    if (!inProcess) {
      filterAuth();
    }
  }, [tenantData]);

  return {
    isEnable,
    filterAuth,
    hint
  };
};

export default useLayoutHooks;
