import { useMsal } from '@azure/msal-react';
import { Box, Grid, Typography } from '@mui/material';
import CustomToolTip from 'components/StylesTooltip';
import Buttons from 'components/buttons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import storage from 'utils/storage';

const StepThree: FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { permissions } = useAppSelector((state) => state.accounts);

  const handleLogin = async () => {
    if (!storage.getAccountsPermission()) {
      storage.setAccountsPermission(permissions);
    }
    instance.initialize();
    navigate('/accounts/callback');
  };

  return (
    <Grid container spacing={2} marginTop={0.5} maxWidth={'1035px'} sx={{ mx: 'auto' }}>
      <Grid item xs={10.5}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(24.5px)',
            padding: '24px',
            borderRadius: '16px'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}>
            <Box sx={{ position: 'relative' }}>
              <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={0.5}>
                Authenticate with Azure AD
              </Typography>
              <CustomToolTip
                top={5}
                right={128}
                component={
                  <Box>
                    <Typography variant="body2" gutterBottom fontSize={16} lineHeight="20px" mb={0.5}>
                      Please note that you will need to have a Global Admin role on your Azure
                      account in order to grant the below permissions:
                    </Typography>
                    <Typography variant="body2" gutterBottom fontSize={16} lineHeight="20px" mb={0.2}>
                      1. Application read
                    </Typography>
                    <Typography variant="body2" gutterBottom fontSize={16} lineHeight="20px" mb={0.5}>
                      2. Azure Service Management
                    </Typography>
                  </Box>
                }
              />
              <Typography variant="body2" gutterBottom fontSize={16} lineHeight="22px" mb={0}>
                Connect to Microsoft Azure to get metrics from Azure VMs
              </Typography>
            </Box>
            <Buttons
              disabled={permissions?.AUDIT || permissions.AUTO_SCALER ? false : true}
              btnVarient="contained"
              text="Login"
              onClick={handleLogin}
              sx={{ width: 100 }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StepThree;
