import { SagaIterator } from '@redux-saga/core';
import { call, delay, put, take, takeEvery, cancelled, fork, cancel } from 'redux-saga/effects';
// local import
import * as slice from '.';
import { getCloudEntityStatusesListApi, getCloudLevelEntityStatusCountApi } from '../features/shared/api';
import { CloudEntityStatusesListApiPayloadType } from '../features/shared/types';

export function* onGetCloudLevelEntityStatusCount(): SagaIterator {
    try {
      const data = yield call(() => getCloudLevelEntityStatusCountApi());
      yield put(slice.getCloudLevelEntityStatusCountFulfilled(data.data));
    } catch (e: any) {
      const err =  e?.message ? e.message :  e
      yield put(slice.getCloudLevelEntityStatusCountRejected(err));
    }
  }

  function* onGetPollingGetCloudEntityStatusesList(payload: CloudEntityStatusesListApiPayloadType): SagaIterator {   
    try {
      while (true) {
        const data = yield call(() => getCloudEntityStatusesListApi(payload));
        yield put(slice.pollingGetCloudEntityStatusesListFulfilled(data.data));
        yield delay((payload.pollingDelay || 0) * 1000); 
      }
    } finally {
      yield cancelled()
    }
  }

  export function* startPollingSaga( payload : {
    type: 'string',
    payload: CloudEntityStatusesListApiPayloadType
  }): SagaIterator {
    const pollingTask = yield fork(onGetPollingGetCloudEntityStatusesList, payload.payload); 
    yield take('accounts/stopPollingGetCloudEntityStatusesList');
    yield cancel(pollingTask); 
  }

export function* accountWatcherSaga(): SagaIterator {
  yield takeEvery('accounts/getCloudLevelEntityStatusCount', onGetCloudLevelEntityStatusCount);
  yield takeEvery('accounts/startPollingGetCloudEntityStatusesList', startPollingSaga);
}

export default accountWatcherSaga;